const english = {
	'Copyright': "© 2024, CashMonitor, All rights reserved.",
	'All rights reserved': "All rights reserved.",
	'Unknown': "Unknown",
	'Cash': "Cash",
	'Coin': "Coin",
	'Note': "Note",
	'Recylers': "Recylers",
	'Cashbox': "Cashbox",
	'Loader': "Loader",
	'Total': "Total",
	'Recycler-Hopper': "Recycler/Hopper",
	'Recycler': "Recycler",
	'Hopper': "Hopper",
	'TotalCashbox': "Cashbox NOTES (Mixed)",
	'TotalCashboxAll': "Cashbox ALL",
	'TotalCashboxCoins': "Cashbox COINS",
	'TotalCashboxNotes': "Cashbox NOTES",
	'TotalLoader': "Loader",
	'Dashboard': "Dashboard",
	'Reports': "Reports",
	'Environment': "Environment",
	'Box list': "Box list",
	'Cashiers': "Cashiers",
	'Inventories': "Inventories",
	'Transactions': "Transactions",
	'Transaction': "Transaction",
	'Messages': "Messages",
	'Date': "Date",
	'Device': "Device",
	'Location': "Location",
	'Type': "Type",
	'Value': "Value",
	'Currency': "Currency",
	'Low stop': "Low stop",
	'High stop': "High stop",
	'Low warning': "Low warning",
	'High warning': "High warning",
	'Quantity': "Quantity",
	'Level': "Level",
	'OK': "OK",
	'CANCEL': "CANCEL",
	'CLEAR': "CLEAR",
	'Inventory for': "Inventory for",
	"No box available":"No box available",
	"Details":"Details",
	"Previous inventory total":"Previous inventory total",
	"Empty":"Empty",
	"Full":"Full",
	"Daily operations":"Daily operations",
	"Sale":"Sale",
	"Refund":"Refund",
	"Refill":"Refill",
	"Pickup":"Pickup",
	"Last operation":"Last operation",
	"View report cashier":"View report cashier",
	"View inventory":"View inventory",
	"View report": "View report",
	"Report": "Report",
	"Weekly cash in": "Weekly cash in",
	"Voucher":"Voucher",
	"Other":"Other",
	"Normal":"Normal",
	"Search":"Search",
	"No available elements":"No available elements",
	"Total cash":"Total cash",
	"Current cash":"Current cash",
	"Lifetime cash sum":"Lifetime cash",
	"Lifetime vips sum":"Lifetime voucher",
	"Lifetime other sum":"Lifetime other",
	"Sales":"Sales",
	"Compare to yesterday":" compare to yesterday",
	"Map":"Map",
	"Quick search":"Quick search",
	"Filter management":"Filter management",
	"Simple filter management":"Simple filter management",
	"Advanced filter management":"Advanced filter management",
	"No filter selected":"No filter selected",
	"Create filter":"Create filter",
	"Edit filter":"Edit filter",
	"Remove filter":"Remove filter",
	"Apply":"Apply",
	"Filter name":"Filter name",
	"selected elements":"selected elements",
	"Filter already exists":"Filter already exists",
	"Filter added":"Filter added",
	"Cancel": "Cancel",
	"Navigate":"Navigate",
	"Results":"Results",
	"Result":"Result",
	"No available data":"No data available",
	"Daily transaction number":"Today transaction number",
	"Transaction number":"Transaction number",
	"Transaction total": "Total",
	"Daily cash in": "Daily cash in",
	"Cash in": "Cash in",
	"Average Cash in": "Average Cash in",
	"Cash in Number": "Cash in Number",
	"Cash in Number mobile": "Count",
	"No device detected": "No device detected",
	"Hardware global state": "Hardware global state",
	"Hardware online": "Online",
	"Hardware warning": "Warning",
	"Hardware offline": "Offline",
	"Hardware stopped": "Stopped",
	"Hardware with maintenance required": "With maintenance required",
	"Hardware under maintenance": "Under maintenance",
	"Box(es)": "Box(es)",
	"Box": "Box",
	"Device works properly": "Device works properly",
	"Devices work properly": "Devices work properly",
	"Manager": "Manager",
	"Device is stopped": "Device is stopped",
	"Account": "Account",
	"My Account": "My Account",
	"Refresh data": "Refresh data",
	"Print": "Print",
	"Setting": "Setting",
	"Export": "Export",
	"No image available": "No image available",
	"Inventory": "Inventory",
	"Comparisons": "Time Comparisons",
	"Operations": "Backoffice Operations",
	"Distributions": "Distributions Cash In",
	"Period": "Period",
	"Gap": "Gap",
	"Timetable": "Timetable",
	"Turnover": "Turnover",
	"Average Price": "Average Price",
	"Sale Number": "Sale Number",
	"And": "And",
	"From": "From",
	"To": "To",
	"At": "At",
	"Start": "Start",
	"End": "End",
	"Terminal": "Terminal",
	"Cashier": "Cashier",
	"First Page": "First Page",
	"Previous Page": "Previous Page",
	"Next Page": "Next Page",
	"Last Page": "Last Page",
	"All": "All",
	"Rows per page": "Rows per page",
	"of": "of",
	"Cancellation": "Cancellation",
	"Amount": "Amount",
	"PDF creation": "PDF creation",
	"PDF export": "PDF export",
	"Printing": "Printing",
	"Table": "Table",
	"Graphic": "Graphic",
	"Table and Graphic": "Table and Graphic",
	"Send as CSV": "Send as CSV",
	"Send as PDF": "Send as PDF",
	"Send as PNG": "Send as PNG",
	"Export as CSV": "Export as CSV",
	"Export as PDF": "Export as PDF",
	"Export as PNG": "Export as PNG",
	"Export as SVG": "Export as SVG",
	"SubTotal": "SubTotal",
	"My Data": "My Data",
	"My Devices": "My Connected Devices",
	"My Scheduled Tasks": "My Scheduled Tasks",
	"My Alerts": "My Alerts",
	"My Preferences":"My Preferences",
	"My Messages": "My Messages",
	"Email": "Email",
	"Id": "Id",
	"Firstname": "Firstname",
	"Lastname": "Lastname",
	"Node": "Node",
	"Role": "Role",
	"Admin": "Admin",
	"Root": "Root",
	"User": "User",
	"Phone": "Phone",
	"Connected on this device since": "Connected on this device since",
	"Basic info": "Basic info",
	"Connection info": "Connection info",
	"Contact info": "Contact info",
	"Name": "Name",
	"Not Specified": "Not Specified",
	"Verified": "Verified",
	"Pending verification": "Pending verification",
	"This devices": "This devices",
	"Other devices": "Other devices",
	"Reseller Id": "Reseller Id",
	"Client Id": "Your Id",
	"Software info": "Software info",
	"Hardware info": "Hardware info",
	"Operating system": "Operating system",
	"Browser": "Browser",
	"Protocol": "Protocol",
	"See details": "See details",
	"No other devices connected": "No other devices connected",
	"Other connected devices": "Other connected devices ",
	"No scheduled task": "No scheduled task",
	"Scheduled Task Configuration":"Scheduled Task Configuration",
	"Delete Scheduled Task":"Delete Scheduled Task",
	"Are you sure":"Are you sure ?",
	"Confirm delete":"Do you really want to delete this item ? this process cannot be undone.",
	"Confirm disable account": "Disable the account ?",
	"Confirm enable account": "Enable the account ?",
	"Confirm delete account": "Do you really want to delete this account ? this process cannot be undone and will remove accounts and users.",
	"Scheduled time":"Scheduled time",
	"No settings for the scheduled task":"No settings for the scheduled task",
	"None":"None",
	"Select type":"Select type",
	"Select filter":"Select filter",
	"Select document format":"Select document format",
	"Select node":"Select node",
	"Select language":"Select language",
	"Select box":"Select pay station",
	"Select device":"Select device",
	"Add":"Add",
	"Recipients":"Recipients",
	"Home":"Home",
	"Disconnection":"Disconnection",
	"Disconnected for inactivity": "Disconnected for inactivity",
	"Reload":"Reload",
	"Title error on operation": "Error list related to this operation",
	"ERROR_INVALID_EXCHANGE_RATE": "Invalid exchange rate",
	"ERROR_FOR_CURRENCY_PARAM": "for the currency",
	"Send":"Send",
	"Edited on": "Edited on",
	"By": "By",
	"Page": "Page",
	'Scheduled report':'Scheduled report',
	'Manual email body':'This email was sent manually by',
	'Manual email subject':'Report received from CashMonitor',
	"Favorite recipients": "Favorite recipients",
	"Other recipients": "Add other recipients",
	"On terminal": "On",
	"All boxes": "All boxes",
	"Inventory alarm level":"Alarm level on inventory",
	"Inventory warning level":"Start to warn at",
	"error":"Error",
	"manual":"Manual",
	"toLow":"Quantity to low level",
	"toNormal":"Quantity to normal level",
	"toHigh":"Quantity to high level",
	"levelColor":"level color",
	"low":"Low",
	"normal":"Normal",
	"high":"High",
	"unknown":"Unknown",
	"Inventory alarm frequency":"Repeat alarm every",
	"Minutes":"Minutes",
	"Hours":"Hours",
	"Days":"Days",
	"Administration":"Administration",
	"Manage Users":"Manage Users",
	"Manage Groups":"Manage Groups",
	"Manage Nodes":"Manage Nodes",
	"Manage Installations":"Manage Installations",
	"Manage Accounts":"Manage Accounts",
	"Installations":"Installations",
	"Add user":"Add user",
	"New":"New",
	"Disconnected after user changed":"Your user has been modified, a new sign in is required.",
	"Filter by roles":"Filter on roles",
	"Filter by types":"Filter on types",
	"Filter by names":"Filter on names",
	"Required field":"This field is required",
	"Invalid field":"Invalid entry (%format%)",
	"Email format":"example@domain.com",
	"Phone format":" E.164 : +33600000000",
	"Delete":"Delete",
	"Edit":"Edit",
	"Add reseller":"Add a reseller",
	"Add customer":"Add a customer",
	"Add location":"Add a location",
	"Add company":"Add a company",
	"Add shop":"Add a shop",
	"Add terminal":"Add a terminal",
	"Add box":"Add box",
	"Add device":"Add device",
	"Move to another box":"Move to another box",
	"Move to another location":"Move",
	"Move to another customer":"Move to another customer",
	"Move to another reseller":"Move to another reseller",
	"Remote command":"Remote command",
	"Reseller":"Reseller",
	"Customer":"Customer",
	"Company":"Company",
	"Shop":"Shop",
	"Place":"Place",
	"Box-Node":"Box",
	"Device-Node":"Device",
	"Image":"Image",
	"Drag and drop file":"Drag and drop file",
	"Address":"Address",
	"Street":"Street",
	"Zip code":"Zip code",
	"City":"City",
	"Country":"Country",
	"Coordinates":"Coordinates",
	"Latitude":"Latitude",
	"Longitude":"Longitude",
	"Invalid coordinate":"Invalid coordinate",
	"Unique upload":"You can only upload one file at the time",
	"Select destination":"Select destination",
	"User access":"User access",
	"Show locked device":"Show locked devices",
	"Show path without devices":"Show path without devices",
	"Hide locked device":"Hide locked devices",
	"Hide path without devices":"Hide path without devices",
	"Recycle useless nodes":"Delete useless nodes",
	"Delete node and related users":"Delete node and related users",
	"Activations":"Activations",
	"Hardwares":"Hardwares",
	"Activation overview":"Activation overview",
	"Activated device overview":"Activated device overview",
	"Cumulative quantity":"Cumulative quantity",
	"Yesterday":"Yesterday",
	"Last 7 days":"Last 7 days",
	"Last 30 days":"Last 30 days",
	"Device activation timeline":"Device activation timeline",
	"Activated devices group by types":"Activated devices group by types",
	"Last inventory at":"Last inventory at",
	"manufacturer":"Manufacturer",
	"name":"Name",
	"version":"Version",
	"date":"Date",
	"socket_count":"Socket count",
	"architecture":"Architecture",
	"computer_name":"Computer name",
	"disk_free":"Disk free space",
	"disk_size":"Disk size",
	"memory_free":"Memory free space",
	"memory_size":"Memory size",
	"serial":"Serial",
	"sku":"Stock keeping unit",
	"terminal_service":"Terminal service",
	"ip_address":"IP address",
	"mac_address":"MAC address",
	"horizontal_resolution":"Resolution",
	"vertical_resolution":"Vertical_resolution",
	"baseboard":"Motherboard",
	"bios":"BIOS",
	"processor":"Processor",
	"os":"Operating system",
	"network":"Network",
	"video":"Video",
	"YES":"Yes",
	"NO":"No",
	"vm":"Virtual machine",
	"hyperv_type":"Hyper-V type",
	"run_on":"Virtualized",
	"vmware_type":"VMware type",
	"software":"Software",
	"model":"Model",
	"organization":"Organization",
	"run_on_server":"Server",
	"run_through_tse":"Remote Desktop Services",
	"Devices":"Devices",
	"Overview":"Overview",
	"Configuration details":"Configuration details",
	"Unit number":"Unit number",
	"Units grouped by memory sizes":"Units group by memory sizes",
	"Units grouped by architectures":"Units group by architectures",
	"Units grouped by manufacturers and processors":"Units group by manufacturer and processor",
	"Units grouped by screen resolutions":"Units group by screen resolution",
	"Units grouped by OS versions":"Units group by OS version",
	"Devices grouped models":"Devices grouped models",
	"Softwares grouped by models and versions":"Softwares grouped by models and versions",
	"Forecasts":"Forecasts",
	"Input": "Input",
	"Output": "Output",
	"Min alert":"Min alert",
	"Max alert":"Max alert",
	"No available forecast":"Aucune prévision possible",
	"Days before max capacity":"estimated days before max capicity",
	"Days before empty":"estimated days before emptiness",
	"Months before max capacity":"Few months estimated days before max capicity",
	"Months before empty":"Few months estimated days before emptiness",
	"Today":"Today",
	"Cancelled operation":"Cancelled operation",
	"Generated":"Generated",
	"Expand":"Expand",
	"Collapse":"Collapse",
	"Email already used":"Email already used",
	"Administrator":"Administrator",
	"Change my password":"Change my password",
	"Password modification":"Password modification",
	"Current password":"Current password",
	"New password":"New password",
	"Confirm password":"Confirm password",
	"Error password length":"Your password is too short ( 8 )",
	"Error new password invalid":"Your new password is too short ( 8 )",
	"Error password mismatches":"Your new password doesn't match confirmation",
	"Incorrect username or password.":"Incorrect username or password.",
	"Unknown error":"Unknown error",
	"No message pending":"No message pending",
	"Password has changed":"Password successfully changed",
	"Too short":"Too short",
	"Weak":"Weak",
	"Okay":"Okay",
	"Good":"Good",
	"Strong":"Strong",
	"PING":"Ping",
	"ENABLE_DATA_SENDING":"Enable data sending",
	"INIT_PARAMETERS":"Initialize parameters",
	"DIAGNOSTIC":"Diagnostic",
	"RESET":"Reset",
	"RESTART":"Restart",
	"FORCE_OUT":"Force out",
	"REVOKE_LICENSE":"Revoke license",
	"Command to execute":"Command to execute",
	"Data type":"Data type",
	"TECHNICAL":"Technical",
	"FINANCIAL":"Financial",
	"Enabling":"Enabling",
	"ENABLED":"Enabled",
	"DISABLED":"Disabled",
	"Reseller id":"Reseller id",
	"Customer code":"Customer code",
	"The command":"The command ",
	"is executing":"is executing",
	"has finished":"has finished",
	"has failed with reason":"has failed with raison",
	"Diagnostic":"Diagnostic",
	"mainModule":"Main module",
	"bundler":"Bundler",
	"spine":"Spine",
	"recycler":"Recyclers",
	"cashbox":"Cashbox",
	"loader":"Loader",
	"usb":"USB",
	"power":"Power supply",
	"acceptor":"Acceptor",
	"r1-2":"Recycler 1 & 2",
	"r3-4":"Recycler 3 & 4",
	"r5-6":"Recycler 5 & 6",
	"acceptor_input":"Acceptor input tray",
	"acceptor_output":"Acceptor output tray",
	"hopper":"Hopper",
	"J-":"D -",
	"Basic":"Basic",
	"Expert":"Expert",
	"Last check at":"Last check at",
	"Cycles":"Nb cycles",
	"Operational since":"Operational since",
	"Total up time":"Total up time",
	"Up Time":"Up Time",
	"Year":"Y",
	"Day":"D",
	"Hour":"H",
	"Minute":"m",
	"Seconde":"s",
	"Slippage":"Slippage",
	"Cycles since last maintenance":"Cycles since last maintenance",
	"Dispensed":"Dispensed",
	"LevelChangeCount":"Level change count",
	"ModuleChangedCount":"Module changed count",
	"ModuleFullCount":"Module full count",
	"ModuleRemovalCount":"Module removal count",
	"InternalResetCount":"Internal reset count",
	"PowerUpCount":"Power up count",
	"BillErrorCount":"Bill error count",
	"BillJamCount":"Bill jam count",
	"HardwareFailureCount":"Hardware failure count",
	"TransportErrorCount":"Transport error count",
	"NotArmedCount":"Not armed count",
	"MissingModuleCount":"Missing module count",
	"ResetWithCoverOpenCount":"Reset with cover open count",
	"ResetWithInterlockOpenCount":"Reset with interlock open count",
	"AmountNotAvailableCount":"Amount not available count",
	"BillNotAvailableCount":"Bill not available count",
	"BillRequestedCount":"Bill requested count",
	"DirectFromLoaderCount":"Direct from loader count",
	"Lifetime dispensed":"Lifetime dispensed",
	"Coins accepted":"Coins accepted",
	"Coins sorted":"Coins sorted",
	"Coins dispensed":"Coins dispensed",
	"Coins error":"Coins error",
	"Coins timeout":"Coins timeout",
	"Coins overcurrent":"Coins overcurrent",
	"AcceptanceRate":"Acceptance Rate",
	"AcceptedCoins":"Accepted Coins",
	"AcceptorJams":"Acceptor Jams",
	"AcceptorProcessedCoins":"Acceptor Processed Coins",
	"AccountancyErrorRate":"Accountancy Error Rate",
	"DispenserJams":"Dispenser Jams",
	"JamRate":"Jam Rate",
	"MiscreditedCoins":"Miscredited Coins",
	"MissortedCoins":"Missorted Coins",
	"MissortingErrorRate":"Missorting Error Rate",
	"PayInVolumeRate":"Pay In Volume Rate (>= 50 coins)",
	"SortedCoins":"Sorted Coins",
	"SystemProcessedCoins":"System Processed Coins",
	"TransactionMisrouteRate":"Transaction Misroute Rate",
	"PowerUps":"Power Ups",
	"PowerUpsCozIndependentWatchdogReset":"Power Ups Coz Independent Watchdog Reset",
	"PowerUpsCozLowPowerManagementReset":"Power Ups Coz Low Power Management Reset",
	"PowerUpsCozResetFromNrstPin":"Power Ups Coz Reset From Nrst Pin",
	"PowerUpsCozSoftwareReset":"Power Ups Coz Software Reset",
	"PowerUpsCozWindowWatchdogReset":"Power Ups Coz Window Watchdog Reset",
	"PowerUpsCozPorPdrReset":"Power Ups Coz Por Pdr Reset",
	"AcceptorKnownCoinsRejected":"Known Coins Rejected",
	"AcceptorUnknownCoinsRejected":"Unknown Coins Rejected",
	"Unsupported":"Unsupported",
	"CoinWasSlug":"Coin was slug",
	"Rejected disabled":"Rejected disabled",
	"Rejected inhibit":"Rejected inhibit",
	"Misroute before":"Misroute before",
	"Misroute after":"Misroute after",
	"PayInVolumeTransaction":"Pay in volume transactions",
	"1To4Coins":"1 To 4 Coins",
	"5To9Coins":"5 To 9 Coins",
	"10To19Coins":"10 To 19 Coins",
	"20To29Coins":"20 To 29 Coins",
	"30To39Coins":"30 To 39 Coins",
	"40To49Coins":"40 To 49 Coins",
	"50To99Coins":"50 To 99 Coins",
	"100To199Coins":"100 To 199 Coins",
	"200To299Coins":"200 To 299 Coins",
	"300To399Coins":"300 To 399 Coins",
	"400To499Coins":"400 To 499 Coins",
	"500To999Coins":"500 To 999 Coins",
	"out of":"out of",
	"Transaction count":"Transaction count",
	"Hardware":"Hardware",
	"Software":"Software",
	"Acceptance":"Acceptance",
	"Compatibility":"Compatibility",
	"Serie":"Serie",
	"Version":"Version",
	"Recyclable":"Recyclable",
	"Capacity":"Capacity",
	"Notes stacked":"Notes stacked",
	"Removal count":"Removal count",
	"Full capacity count":"Full capacity count",
	"Out of service for recycler opened":"Out of service for recycler opened",
	"Notes inserted while recycler busy":"Notes inserted while recycler busy",
	"Motor starts":"Motor starts",
	"Banknotes stacked":"Banknotes stacked",
	"Flash download attempts":"Flash download attempts",
	"Host resets":"Host resets",
	"Calibrations":"Calibrations",
	"Resets":"Resets",
	"Banknotes passed recognition":"Banknotes passed recognition",
	"Barcodes decoded":"Barcodes decoded",
	"Docs reached escrow position":"Docs reached escrow position",
	"Docs passed validation":"Docs passed validation",
	"Notes floated down":"Notes floated down",
	"Recognition rejections":"Recognition rejections",
	"Document disabled rejections":"Document disabled rejections",
	"Orientation disabled rejections":"Orientation disabled rejections",
	"Host return document rejections":"Host return document rejections",
	"Escrow timeout rejections":"Escrow timeout rejections",
	"Fast feed error rejections":"Fast feed error rejections",
	"Security rejections":"Security rejections",
	"Docs that failed to reach escrow position":"Docs that failed to reach escrow position",
	"Docs inserted while disabled":"Docs inserted while disabled",
	"Docs inserted while recycler busy":"Docs inserted while recycler busy",
	"Docs less than mini allowable length":"Docs less than mini allowable length",
	"Docs greater than maxi allowable length":"Docs greater than maxi allowable length",
	"Rejections for other reasons":"Rejections for other reasons",
	"All types of jams":"All types of jams",
	"Out of service for jammed":"Out of service for jammed",
	"Out of order conditions":"Out of order conditions",
	"Jam recovery efforts":"Jam recovery efforts",
	"Out of service conditions":"Out of service conditions",
	"Out of service for jammed on reset":"Out of service for jammed on reset",
	"Manage users":"Manage users",
	"Close period":"Close period",
	"Notify":"Notify",
	"Filter installation by name":"Filter installation by name",
	"Filter installation by software":"Filter installation by software",
	"Filter account by name":"Filter account by name",
	"Filter account by type":"Filter account by type",
	"Filter outdated software versions":"Filter outdated software versions",
	"Accounts":"Accounts",
	"Create reseller account":"Create reseller account",
	"Create customer account":"Create customer account",
	"Disable":"Disable",
	"Enable":"Enable",
	"Remove inactive":"Remove inactive",
	"Last communication": "Last communication",
	"Simple mode": "Simple mode",
	"Advanced mode": "Advanced mode",
	"Available": "Available",
	"Selected": "Selected",
	"Your selection": "Your selection",
	"Your reports": "Your reports",
	"No selection": "No selection",
	"Select a reseller": "Select a reseller",
	"Select a customer": "Select a customer",
	"Select a pay station list": "Select a pay station list",
	"Select a filter": "Select a filter",
	"Groups": "Groups",
	"Create group": "Create group",
	"Manage group users": "Manage group users",
	"Edit group": "Edit group",
	"Delete group": "Delete group",
	"Group creation": "Group creation",
	"Group modification": "Group modification",
	"Data type allowed": "Data type allowed",
	"Financial data": "Financial data",
	"Technical data": "Technical data",
	"Yes": "Yes",
	"No": "No",
	"Filter groups by name": "Filter groups by name",
	"Filter locked groups": "Filter locked groups",
	"Attach users on group": "Attach users on group",
	"Select users": "Select users",
	"Error warning alarm": "The warning can't exceed alarm level",
	"Groups for the user":"Groups for the user",
	"Close":"Close",
	"Invalid fund type detection": "An invalid fund type has been detected",
	"Group targets": "Group targets",
	"Manage options": "Manage my options",
	"Account options": "My account options",
	"Technical alerts recipients": "Technical alert(s) recipient(s)",
	"Alert if inactive for ( days )": "Alert if inactive for ( days )",
	"Days of inactivity": "Days of inactivity",
	"Scheduled_disclaimer": "Your scheduled task will be triggered every 5 minutes. The email may be sent with a slight delay.",
	"Detailed": "Detailed",
	"Consolidated": "Consolidated",
	"Select granularity": "Select granularity",
	"Remote commands": "Remote commands",
	"Customization": "Customization",
	"License": "License",
	"Serial": "Serial number",
	"INTERNAL":"Internal",
	"ADMINISTRATIVE":"Administrative",
	"SALE_ROLE":"Sale",
	"Licenses": "Licenses",
	"Manage Licenses": "Manage Licenses",
	"Licenses allowed ( -1 for unlimited )": "Licenses allowed",
	"Licenses count": "Licenses count",
	"Invalid value": "Invalid value",
	"Installations requires a license": "Installations requires a license",
	"Not enough licenses": "Not enough licenses",
	"Available licenses": "Available licenses",
	"Unlimited": "Unlimited",
	"Users": "Users",
	"Select groups": "Select groups",
	"Licenses assignment": "Licenses assignment",
	"Licenses history": "Licenses history",
	"Assignment": "Assignment",
	"History": "History",
	"Old value": "Old value",
	"New Value": "New Value",
	"Movement": "Movement",
	"Last reception": "Last reception",
	"Technical alerts": "Technical alerts",
	"No alert": "No alert",
	"Installation details": "Installation details",
	"No Installation selected": "No installation selected",
	"OFFLINE": "Offline",
	"ONLINE": "Online",
	"PARTIALLY": "Partially",
	"STOPPED": "Stopped",
	"Modules state": "Modules state",
	"Last important messages": "Last important messages",
	"Error found": "Error found",
	"Last error": "Last error",
	"No error available": "No error available",
	"Monitoring": "Monitoring",
	"Restart the software": "Restart the software",
	"Restart the service": "Restart the service",
	"Recommendations": "Recommendations",
	"Tools": "Tools",
	"Reseller/Client": "Reseller/Client",
	"Account name": "Account name",
	"Info": "Information",
	"Provider": "Supplier",
	"TODAY": "Today",
	"YESTERDAY": "Yesterday",
	"CURRENT_WEEK": "Current week",
	"CURRENT_MONTH": "Current month",
	"PREVIOUS_WEEK": "Previous week",
	"PREVIOUS_MONTH": "Previous month",
	"RESEND_MAIL": "Resend invitation",
	"Filter account" : "Filter account",
	"level_cleanup": "Cleanup required",
	"level_maintenance": "Maintenance required",
	"ACTION_OK": "OK",
	"CONFIRM_RESEND": "Resend invitation ?",
	"STATUS": "Status",
	"LAST_CONNECTION": "Last connection",
	"FILTER_INSTALLATION": "Filter installation",
	"DESACTIVATED_USER": "Desactivated user",
	"Type your email": "Type your email",
	"Subject": "Subject",
	"New message":"New message",
	"send you a message":"send you a message",
	"Hello":"Hello",
}

export default english;
