import React from 'react';
import { I18n } from 'aws-amplify/utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DialogUserData from "./DialogUserData";
import DialogConfirmRemove from "../DialogConfirmRemove/DialogConfirmRemove";

import {Search} from "@mui/icons-material";
import NoDataPlaceholder from "../NoDataPlaceholder/NoDataPlaceholder";
import PhoneNumberFormatter from "../../../Utils/PhoneNumberFormatter";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from '@mui/material/IconButton';
import {
	TextField,
	List,
	InputAdornment,
	ListItem, Tooltip
} from "@mui/material";

import {
	deleteUser,
	deleteUserAlert, deleteUserTask,
	insertUser,
	setUserAlert, setUserTask,
	updateUser,
	enableUser,
	sendUserInvitation
} from '../../../graphql/mutations';
import GraphQlTool from "../../../Utils/GraphQlTool";
import {getUsersForAccount, getUserAttributes} from "../../../graphql/queries";

import './InstallationManager.css';
import './UserManager.css';

import UserStorage from "../../../AWS/storage/UserStorage";
import Grid from "@mui/material/Grid";
import UUID from "../../../Utils/UUID";
import DialogConfirmActivation from "../DialogConfirmActivation/DialogConfirmActivation";
import DialogConfirmResend from "../DialogConfirmResend/DialogConfirmResend";

export default function UserManager( props ) {
    const [selected, setSelected] = React.useState( null );
    const [users, setUsers] = React.useState( null );
    const [currentFilter, setCurrentFilter] = React.useState( null );
	const [pendingEdit, setPendingEdit] = React.useState( null );
	const [pendingDelete, setPendingDelete] = React.useState( null);
	const [pendingActivation, setPendingActivation] = React.useState( null);
	const [pendingResend, setPendingResend] = React.useState( null);
	const [currentFilterRole, setCurrentFilterRole] = React.useState( 0);
	const [isLoading, setIsLoading] = React.useState( false );

	const ROOT = 1;
	const ADMIN = 2;
	const USER = 3;

	let cssForm = "select-task-properties";
	let cssSelect = "combo-task-properties";
	let cssLabel = "label-task-properties";

	const factoryUUID = new UUID();

	if( props.isDarkStyle ) {
		cssForm += " dark";
		cssSelect += " dark";
		cssLabel += " dark";
	}

	const addUserToList = user => {
		//exclude Root user that are created in a specific node
		if( props && user.usertype !== ROOT ) {
			let filtered = [];
			let alreadyExists = false;

			filtered.forEach( user => {
				filtered.push( user );
				if( user.cognitoid === user.uuid ) {
					alreadyExists = true;
				}
			} );

			if( alreadyExists ) {
				console.log( "Add warn snack message to tell user we ignore the user add !" )
			} else {
				filtered.push( user );
				retrieveUsersForAccount();
			}
		}
	};

	const handleConfirm = (  mode , pending ) => {
		const phoneFormater = new PhoneNumberFormatter();
		switch( mode ) {
			case "add":
				props.API.graphql({
					query: insertUser,
					variables: {
						input:{
							accountId: ( pending["accountid"] ) ? pending["accountid"] : props.observer.currentUser.accountId,
							name: pending.name,
							email: pending.email,
							phone: phoneFormater.formatInternational( pending.phone?.trim() , props.observer.currentUser.accountLocale ),
							userType: ( pending["usertype"] ) ? pending["usertype"] : 3,
							userRole: ( pending["userrole"] ) ? pending["userrole"] : null,
							locale: props.observer.currentUser.accountLocale
						}
					}
				})
					.then( returned => {
						const tool = new GraphQlTool( 'insertUser' , returned );
						addUserToList( tool.extract() );
					})
					.catch((error) => {
						console.log("error" , error);
					})

				setPendingEdit(null);

				break;
			case "edit":
				props.API.graphql({
					query: updateUser,
					variables: {
						uuid: pending.cognitoid ,
						input: {
							accountId: ( pending["accountid"] ) ? pending["accountid"] : props.observer.currentUser.accountId,
							name: pending.name,
							email: pending.email,
							phone: phoneFormater.formatInternational( pending.phone?.trim() , props.observer.currentUser.accountLocale ),
							userType: ( pending["usertype"] ) ? pending["usertype"] : 3,
							userRole: ( pending["userrole"] ) ? pending["userrole"] : null,
							oldUuid: pending.cognitoid
						}
					}
				})
					.then( returned  => {
						const tool = new GraphQlTool( 'updateUser' , returned );
						const newUser = tool.extract();
						const cognitoUserHasChanged = newUser.uuid !== pending.cognitoid;
						updateUserOnList( newUser , cognitoUserHasChanged , pending.cognitoid );
					})
					.catch((error) => {
						console.log("error" , error);
					});
				setPendingEdit(null);
				break;
			case "delete":
				props.API.graphql({
					query: deleteUser,
					variables: {
						uuid:pending
					}
				})
					.then( returned => {
						const tool = new GraphQlTool( 'deleteUser' , returned );
						removeUserFromList( tool.extract() );
					})
					.catch((error) => {
						console.log("error" , error);
					});
				setPendingDelete(null);
				break;
			case "enable":
				props.API.graphql({
					query: enableUser,
					variables: {
						uuid:pending.cognitoid,
						isActive:!pending.userisactive
					}
				})
					.then( returned => {
						retrieveUsersForAccount();
						setPendingActivation(null);
					})
					.catch((error) => {
						console.log("error" , error)
					});
				break;
			case "resend":
				props.API.graphql({
					query: sendUserInvitation,
					variables: {
						email: pending.Email
					}
				})
				.then( returned => {
					retrieveUsersForAccount();
					setPendingResend(null);
				} )
				.catch((error) => {
                    console.log("error" , error)
                });
				break;
			default:
				setPendingResend(null);
				setPendingActivation(null);
				setPendingEdit(null);
				setPendingDelete(null);
				break;
		}
	};

	const updateUserOnList = ( user , cognitoUserHasChanged , oldUUID ) => {
		let filtered = [];

		filtered.forEach( candidate => {
			if( candidate.userid !== user.userid ) {
				filtered.push( candidate );
			} else {
				filtered.push( user );
			}
		} );

		if( cognitoUserHasChanged ) {
			updateWorkspace( oldUUID , user , cognitoUserHasChanged );
		}
		retrieveUsersForAccount();
	};

	const removeUserFromList = user => {
		let filtered = [];

		filtered.forEach( candidate => {
			if( candidate.cognitoid !== user.uuid ) {
				filtered.push( candidate );
			}
		} );

		let newQueue = [];
		let queuedNodeUserDeletion = [];

		queuedNodeUserDeletion.forEach( uuid => {
			if( uuid !== user.uuid ) {
				newQueue.push( uuid );
			}
		} );
		queuedNodeUserDeletion = newQueue;

		props.API.graphql({ query: deleteUserAlert, variables: { userId:user.uuid }})
			.catch((error) => {
				console.log("error on deleting alert" , error);
			});

		props.API.graphql({ query: deleteUserTask, variables: { userId:user.uuid }})
			.catch((error) => {
				console.log("error on deleting tasks" , error);
			});

		UserStorage.clearStorage( user );
		retrieveUsersForAccount();
		setSelected(null);
	};

	const updateWorkspace = ( oldUUID , newUser , cognitoUserHasChanged ) => {
		//copy alert
		props.API.graphql({ query: deleteUserAlert, variables: {userId:oldUUID}})
			.then( returned => {
				let newAlert = returned.data.deleteUserAlert;
				if( newAlert ) {
					newAlert.userId = newUser.uuid;
					newAlert.updatedAt = new Date().toISOString();
					if( newAlert.lastSentAt ) {
						delete newAlert.lastSentAt;
					}
					props.API.graphql({ query: setUserAlert, variables: { input:newAlert }});
				}
			})
			.catch((error) => {
				console.log("error" , error);
			});
		//copy task
		props.API.graphql({ query: deleteUserTask, variables: {userId:oldUUID}})
			.then((returned) => {
				let newTask = returned.data.deleteUserTask;
				if( newTask ) {
					newTask.userId = newUser.uuid;
					newTask.updatedAt = new Date().toISOString();
					if( newTask.hasOwnProperty( "lastSentAt" ) ) {
						delete newTask.lastSentAt;
					}
					props.API.graphql({ query: setUserTask, variables: { input:newTask }});
				}
			})
			.catch((error) => {
				console.log("error" , error);
			});

		UserStorage.copyStorage( oldUUID , newUser ).then( result => {
			if( newUser.userid === props.observer.currentUser.id ) {
				//to check not sure it's relevant
				/*if( adminInstance.props.observer ) {
					adminInstance.props.observer.onLoggedUserUpdated( newUser , cognitoUserHasChanged );
				}*/
			}
		} );
	};

	const addHandler = () => {
		setPendingEdit({id:0, node:null , accessLevel:3});
	};

	const editHandler = () => {
		setPendingEdit(selected);
	};

	const deleteHandler = () => {
		setPendingDelete( selected.cognitoid );
	};

	const nameMatchFilter = candidate => {
		return candidate?.userlabel?.toLowerCase().includes( currentFilter );
	};

	const emailMatchFilter = candidate => {
		return candidate?.Email?.toLowerCase().includes( currentFilter );
	}

	const phoneMatchFilter = candidate => {
		return candidate?.PhoneNumber?.includes( currentFilter );
	}

    const filterUser = list => {
        if( ! currentFilter ) {
            return list;
        }
        const filtered = [];
        if( list && list.length > 0 ) {
            list.forEach( candidate => {
                if( nameMatchFilter( candidate ) || emailMatchFilter( candidate ) || phoneMatchFilter( candidate ) ) {
                    filtered.push( candidate );
                }
            } );
        }
        return filtered;
    };

    const retrieveUsersForAccount = () => {
    		if( props.API && props.observer && props.observer.currentUser && props.observer.currentUser.accountId > 0 ) {
    			props.API
    				.graphql({ query: getUsersForAccount, variables: { accountId: props.observer.currentUser.accountId }})
    				.then( returned => {
    					const tool = new GraphQlTool( 'getUsersForAccount' , returned );
    					loadUsersExtraData( tool.extract() );
    				})
    				.catch(error => {
    					console.error("error" , error);
    				})
    		}
    };
    
    const loadUsersExtraData = accountUsers => {
    		if( accountUsers ) {
    			const done = [];
    			accountUsers.forEach( user => {
    				if( props.API && props.observer &&
    					user && hasValidCognitoId( user.cognitoid ) ) {
    					props.API
    						.graphql({ query: getUserAttributes, variables: { cognitoId: user.cognitoid }})
    						.then( returned => {
    							const cognitoData = JSON.parse( returned.data.getUserAttributes.replaceAll( "=" , ":" ) ).Users[0];
    							user.Username = cognitoData.Username;
    							user.UserStatus = cognitoData.UserStatus;
    							user.UserCreateDate = cognitoData.UserCreateDate;
    							user.UserLastModifiedDate = cognitoData.UserLastModifiedDate;
    							user.PhoneNumberVerified = extractAttribute( "phone_number_verified" , "false" , cognitoData.Attributes );
    							user.PhoneNumber = extractAttribute( "phone_number" , "" , cognitoData.Attributes );
    							user.Email = extractAttribute( "email" , "" , cognitoData.Attributes );
    
    							done.push( user );
    							if( done.length === accountUsers.length ) {
									setIsLoading( false );
									props.onLoadingComplete( accountUsers );
    								setUsers( accountUsers );
    							}
    						})
    						.catch(error => {
    							console.error("error" , error);
    							console.error("Invalid user" , user);
    							done.push( user );
    							if( done.length === accountUsers.length ) {
									setIsLoading( false );
    								setUsers( accountUsers );
    							}
    						})
    				}
    
    
    			} );
    		}
    	};

    const hasValidCognitoId = cognitoId => {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return uuidRegex.test(cognitoId );
    };

    const extractAttribute = ( key , defaultValue , data ) => {
        let returned = defaultValue;
        if( data ) {
            data.forEach( candidate => {
                if( candidate.Name === key ) {
                    returned = candidate.Value;
                }
            } );
        }
        return returned;
    };
    
    const resendHandler = () => {
		if( ! resendButtonDisabled ) {
			setPendingResend(selected);
		}
    };

    const onFilterChanges = evt => {
    	setCurrentFilter( evt.target.value );
    };

	const onFilterByRoleChanged = newValue => {
		setCurrentFilterRole(newValue);
	}

	const handleSelectChange = evt => {
			onFilterByRoleChanged( evt.target.value );
	};

	const handleClickDisableAccount = () => {
		if( ! selected ) return;
		setPendingActivation(selected);
	};

	const filterByRoles = filtered => {
		if( currentFilterRole !== 0 ) {
			if( filtered ) {
				let list = [];
				filtered.forEach( (candidate) => {
					if( candidate.usertype === currentFilterRole ) {
						list.push( candidate );
					}
				} );
				return list;
			}

		}
		return filtered;
	}

	const renderSelectType = () => {
		const isSimpleUSer = props.observer.currentUser.type !== ROOT && props.observer.currentUser.type !== ADMIN;

		const renderAll = () => {
			if( isSimpleUSer ) {
				return null;
			}

			return (
				<MenuItem value={0} key={factoryUUID.generate()}>
					<FontAwesomeIcon icon={["fas" , "users"]} style={{fontSize:"1.25em"}}/>
					<span className="ml-2">{I18n.get("All")}</span>
				</MenuItem>
			);
		}

		const renderRoot = () => {
			if( props.observer.currentUser.type !== ROOT ) {
				return null;
			}

			return (
				<MenuItem value={1} key={factoryUUID.generate()}>
					<FontAwesomeIcon icon={["fas" , "user-lock"]} style={{fontSize:"1.25em"}}/>
					<span className="ml-2">{I18n.get("Root")}</span>
				</MenuItem>
			);
		}

		const renderAdmin = () => {
			if( isSimpleUSer ) {
				return null;
			}

			return (
				<MenuItem value={2} key={factoryUUID.generate()}>
					<FontAwesomeIcon icon={["fas" , "user-shield"]} style={{fontSize:"1.25em"}}/>
					<span className="ml-2">{I18n.get("Admin")}</span>
				</MenuItem>
			);
		}

		return (
			<Select classes={{selectMenu:cssSelect}}
					defaultValue={ ( isSimpleUSer ) ? 3 : 0 }
					labelId={`user-filter-role-label`}
					id={`user-filter-role`}
					value={props.currentValue}
					onChange={handleSelectChange}
					MenuProps={{
						classes:{paper:cssSelect}
					}}
					label={I18n.get("Filter by types")} >
				{renderAll()}
				{renderRoot()}
				{renderAdmin()}
				<MenuItem value={3} key={factoryUUID.generate()}>
					<FontAwesomeIcon icon={["fas" , "user"]} style={{fontSize:"1.25em"}}/>
					<span className="ml-2">{I18n.get("User")}</span>
				</MenuItem>
			</Select>
		);
	}

	const renderHeader = () => {
		return (
			<div className={`header-admin`}>
				<div className={`action-bar`}>
					<div className={`title`}>{ I18n.get("User") }</div>
					<div className={`count`}>({ ( users ) ? users.length : 0 })</div>
					<div className={`spacer`}></div>
					<div className={`actions`}>
						<div
							className={`button ${ resendButtonDisabled ? 'disabled' : ''}`}
							onClick={resendHandler}>
							{I18n.get("RESEND_MAIL")}
						</div>
						<div className={`button`} onClick={addHandler}>
							{I18n.get("Add user")}
						</div>
						<div className={`button ${ noSelectionOrRoot ? 'disabled' : ''}`}
							 onClick={editHandler}>
							{I18n.get("Edit")}
						</div>
						<div
							className={`button ${(resendButtonDisabled || (selected && selected.userisactive) ) ? 'disabled' : ''}`}
							onClick={deleteHandler}>
							{I18n.get("Delete")}
						</div>
						<div className={`button ${noSelectionOrRoot ? 'disabled' : ''}`}
							 onClick={handleClickDisableAccount}>
							{(selected && selected.userisactive) ? I18n.get("Disable") : I18n.get("Enable")}
						</div>
					</div>
				</div>
				<div className={`filter-bar`}>
					<Grid item xs={12} md={4} lg={12} className="user-search-item">
						<TextField id="search-installations"
								   className={`search-field-expand`}
								   label={I18n.get("Filter account")}
								   variant="outlined"
								   size="small"
								   InputProps={{
									   startAdornment: (
										   <InputAdornment position="start">
											   <Search/>
										   </InputAdornment>
									   ),
								   }}
								   onChange={onFilterChanges}/>
					</Grid>
					<div className={`spacer`}></div>
					<Grid item xs={12} md={4} lg={4} className="user-search-item">
						<FormControl variant="outlined" className={cssForm}
									 disabled={props.observer.currentUser.type === USER}>
							<InputLabel className={cssLabel}
										id={`user-filter-role-input`}>
								{I18n.get("Filter by types")}
							</InputLabel>
							{renderSelectType()}
						</FormControl>
					</Grid>
				</div>
			</div>
		);
	};

	const renderListHeader = () => {
    	    return (
    	        <div className={`list-header payStation-card`}>
    	            <div className={`general-block`}>
                        <div className="userlist-id">{ I18n.get('Id') }</div>
                        <div className="userlist-name">{ I18n.get('Name') }</div>
                        <div className="userlist-account">{ I18n.get('Account') }</div>
                        <div className="userlist-email">{ I18n.get('Email') }</div>
                        <div className="userlist-phone">{ I18n.get('Phone') }</div>
                        <div className="userlist-type">{ I18n.get('Type') }</div>
                        <div className="userlist-groups">{ I18n.get('Groups') }</div>
						<div className="userlist-status">{ I18n.get('STATUS') }</div>
                    </div>
    	        </div>
    	    );
    	};

	const renderListItem = user => {
		return (
			<ListItem key={user.userid}
					  className={`payStation-card ${(selected === user) ? 'selected' : ''}`}
					  onClick={() => {
						  setSelected(user);
					  }}>
				<div className="userlist-element-id">
					{user.userid}
				</div>
				<div className="userlist-element-name">
					{user.userlabel}
				</div>
				<div className="userlist-element-account">
					{user.accountlabel}
				</div>
				<div className="userlist-element-email">
					{user.Email}
				</div>
				<div className="userlist-element-phone">
					{user.PhoneNumber}
				</div>
				<div className="userlist-element-type">
					<FontAwesomeIcon icon={["fas", `${(user.usertype === ROOT) ? 'user-lock' : `${(user.usertype === ADMIN) ? 'user-shield' : 'user'}`}`]}
									 style={{fontSize: "2em"}}/>
				</div>
				<div className="userlist-element-groups">
					<IconButton
						color="inherit"
						aria-label="groups"
						onClick={() => {
							props.onGroupDisplay(user.usergroups)
						}}
						className={`Icon ${(user.usergroups && user.usergroups?.trim() === "") ? "no-click" : ""}`}>

						<FontAwesomeIcon icon="fa-solid fa-users-rectangle" className="display-5"/>
						<span
							className="user-group-count">{(user.usergroups && user.usergroups?.trim() !== "") ? user.usergroups?.split(',').length : 0}</span>
					</IconButton>
				</div>
				<div className={`userlist-element-status ${ user.userisactive ? ( ( user.UserStatus === "CONFIRMED" ) ? "success" : "warning" ) : '' }`}>
					{ user.userisactive ?
						( ( user.UserStatus === "CONFIRMED" ) ?
								<Tooltip title={
									<>
										{I18n.get("LAST_CONNECTION")}: <br/>
										{user?.lastconnection?.split('.')[0].replace('T' , ' ')}
									</>
								}><FontAwesomeIcon icon="fa-solid fa-circle-check" style={{fontSize:"1.25em"}}/></Tooltip> :
								<Tooltip title={user.UserStatus}>
								<FontAwesomeIcon icon="fa-solid fa-circle-exclamation" style={{fontSize:"1.25em"}}/></Tooltip>
						) :
						<Tooltip title={I18n.get("DESACTIVATED_USER")}>
						<FontAwesomeIcon icon={["fas" , "user-slash"]} style={{fontSize:"1.25em"}}/></Tooltip>
					}
				</div>
			</ListItem>
		);
	};

	if ( ! users ) {
		if( ! isLoading ) {
			setIsLoading( true );
			retrieveUsersForAccount();
		}
		return null;
    }

    let filtered = filterUser( filterByRoles ( users ) );
    const items = [];
    const shown = filtered.length < 1;

	const noSelectionOrRoot = !selected || selected.usertype === ROOT;
	const resendButtonDisabled = ( noSelectionOrRoot || selected.userid === props.observer.currentUser.id );

    filtered.forEach( user => {
        items.push( renderListItem( user ) );
    });
	return (
		<React.Fragment>
			<div id="user-manager">
				{renderHeader()}
				{renderListHeader()}
				<List>
					{items.map( item => item )}
				</List>
				<NoDataPlaceholder className="comparison-placeholder-no-data"
								   shown={shown}/>
				<DialogConfirmRemove handleClose={handleConfirm}
									 pendingDelete={pendingDelete}
									 isDarkStyle={props.isDarkStyle}/>
				<DialogConfirmActivation handleClose={handleConfirm}
									 pendingActivation={pendingActivation}
									 isDarkStyle={props.isDarkStyle}/>
				<DialogConfirmResend handleClose={handleConfirm}
										 pendingResend={pendingResend}
										 isDarkStyle={props.isDarkStyle}/>
				<DialogUserData handleClose={handleConfirm}
								user={props.user}
								userRDS={props.observer.currentUser}
								pendingEdit={pendingEdit}
								pendingDelete={pendingDelete}
								isDarkStyle={props.isDarkStyle}
								accounts={props.accounts}/>
			</div>
		</React.Fragment>
	);
}
