import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TableErrorIndicator from './TableErrorIndicator';
import TableManualIndicator from './TableManualIndicator';
import AmountFormatter from '../../../Utils/AmountFormatter';
import UUID from "../../../Utils/UUID";
import { fundType } from "../../../Models/Report/Enums.js";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {I18n} from 'aws-amplify/utils';
import '../../Views/Administration/Administration.css';
import './Table.css';

const factoryUUID = new UUID();
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className=''>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={I18n.get("First Page")}
      >
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={I18n.get("Previous Page")}>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={I18n.get("Next Page")}
      >
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={I18n.get("Last Page")}
      >
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
	if( orderBy === "hour") {
		return parseInt(b[orderBy].substring(0 , b[orderBy].indexOf(" "))) - parseInt(a[orderBy].substring(0 , a[orderBy].indexOf(" ")));
	}
	if( orderBy === "label") {
		if( b[orderBy].typeLabel.localeCompare(a[orderBy].typeLabel) === 0 ) {
			return b[orderBy].optDataLabel.localeCompare(a[orderBy].optDataLabel);
		}
		return b[orderBy].typeLabel.localeCompare(a[orderBy].typeLabel);
	}
	
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const handleClickToggle = ( expanded ) => {
		let divs = document.getElementsByClassName("collapsable " );
		for(let i in divs) {
			if ( divs.hasOwnProperty( i ) ) {
				let div = divs[i];
				if( div.classList ) {
					if( expanded ) {
						if( div.classList.contains("hidden") ) {
							div.classList.remove("hidden")
						}
					} else {
						if( !div.classList.contains("hidden") ) {
							div.classList.add("hidden")
						}
					}
				}
			}
		}
		
		let btns = document.getElementsByClassName("toggle icon " );
		for(let j in btns) {
			if ( btns.hasOwnProperty( j ) ) {
				let btn = btns[j];
				if( btn.classList ) {
					if( !btn.classList.contains("all") ) {
						if( expanded ) {
							btn.classList.remove("shrink")
							btn.classList.add("expand")
						} else {
							btn.classList.remove("expand")
							btn.classList.add("shrink")
						}
					}
				}
			}
		}
	}

	if( props.groupBy ) {
		let isFirst = true;
		return (
			<TableHead>
				<TableRow className="group-by hidden">
					{props.header.map((headCell) => {
						if( headCell.id === props.groupBy ) {
							return (
								<TableCell
									key={headCell.id}
									align={headCell.numeric ? 'right' : 'left'}
									padding={headCell.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === headCell.id ? order : false}
									className={headCell.className}
									colSpan={props.header.length-1}
								>
								</TableCell>
							);
						}
						return null;
					})}
				</TableRow>
				<TableRow>
					{props.header.map((headCell) => {
						if( headCell.id !== props.groupBy ) {
							if( isFirst ) {
								isFirst = false;
								return (
									<TableCell
										key={headCell.id}
										align={headCell.numeric ? 'right' : 'left'}
										padding={headCell.disablePadding ? 'none' : 'normal'}
										sortDirection={orderBy === headCell.id ? order : false}
										className={headCell.className}
									>
										<div className="toggle icon shrink all"
											 onClick={(evt) => handleClickToggle( true )}>
										</div>	
										<div className="toggle icon expand all"
											 style={{marginLeft: ( props.isDesktop ) ? "2em" : "0.25em" , marginRight: ( props.isDesktop ) ? "0" : "1em" }}
											 onClick={(evt) => handleClickToggle( false )}>
										</div>	
										<TableSortLabel
											  active={orderBy === headCell.id}
											  direction={orderBy === headCell.id ? order : 'asc'}
											  onClick={createSortHandler(headCell.id)}
										>
											{headCell.label}
											{orderBy === headCell.id ? (
												<span className='hidden'>
												  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</TableCell>
								);
							} 
							return (
								<TableCell
									key={headCell.id}
									align={headCell.numeric ? 'right' : 'left'}
									padding={headCell.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === headCell.id ? order : false}
									className={headCell.className}
								>
									<TableSortLabel
										  active={orderBy === headCell.id}
										  direction={orderBy === headCell.id ? order : 'asc'}
										  onClick={createSortHandler(headCell.id)}
									>
										{headCell.label}
										{orderBy === headCell.id ? (
											<span className='hidden'>
											  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
							);
						}
						return null;
					})}
				</TableRow>
			</TableHead>
		);
		
	}

	return (
		<TableHead>
			<TableRow>
				{props.header.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						className={headCell.className}
					>
						<TableSortLabel
							  active={orderBy === headCell.id}
							  direction={orderBy === headCell.id ? order : 'asc'}
							  onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className='hidden'>
								  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function SortableTable( props ) {
	const [order, setOrder] = React.useState((props.order === 'desc') ? props.order : 'asc' );
	const [orderBy, setOrderBy] = React.useState( ( props.orderBy ) ? props.orderBy : '' );
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState( ( props.defaultRowsPerPage ) ? props.defaultRowsPerPage : 5 );

	useEffect(() => {
		if( props.onSortingData ) {
			if( props.tag ) {
				props.onSortingData( stableSort(props.rows, getComparator(order, orderBy)) , props.tag );
			} else {
				props.onSortingData( stableSort(props.rows, getComparator(order, orderBy)) );
			}
			
		}
	});

	if( ! props.rows || ! props.header ) {
		return null;
	}

	//const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		if( props.onPageChanged ) {
			props.onPageChanged( newPage );
		}
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage( parseInt( event.target.value, 10 ) );
		if( props.onRowsPerPageChanged ) {
			props.onRowsPerPageChanged( parseInt( event.target.value, 10 ) );
		}
		setPage(0);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const getCss = ( id , amount ) => {
		if( ! id.startsWith("gap") ) {
			if( id === "hour" ) {
				return "cell hour";
			}
			return "cell amount " + id;
		}
		if( amount > 0 ) {
			return "cell positive "  + id;
		} else if ( amount < 0 ) {
			return "cell negative "  + id;
		} else {
			return "cell equals "  + id;
		}
	}

	const extractGroupByFieldList = (rows) => {
		let distinctFields = [];
		rows.map( (row) => {
			if( props.groupBy === "label" ) {
				if( row["boxId"] === null || row["boxId"] === undefined ) {
					if( !distinctFields.includes( row[props.groupBy]["boxId"] ) ) {
						distinctFields.push( row[props.groupBy]["boxId"] );
					}
				} else {
					if( !distinctFields.includes( row["boxId"] ) ) {
						distinctFields.push( row["boxId"] );
					}
				}
				
			} else {
				if( !distinctFields.includes( row[props.groupBy] ) ) {
					distinctFields.push( row[props.groupBy] );
				}
			}
			return true;
		} );
		return distinctFields;
	}

	const extractRowForBoxId = ( rows , boxId ) => {
		let box = null;
		rows.map( (row) => {
			if( box === null ) {
				if( row["boxId"] === null || row["boxId"] === undefined ) {
					if( row[props.groupBy]["boxId"] === boxId ) {
						box = row;
					}
				} else {
					if( row["boxId"] === boxId ) {
						box = row;
					}
				}
			} 
			return true;
		} );
		return box;
	}

	const extractRowsForBoxId = ( rows , boxId ) => {
		let data = [];
		rows.map( (row) => {
			if( row["boxId"] === null || row["boxId"] === undefined ) {
				if( row[props.groupBy]["boxId"] === boxId ) {
					data.push( row );
				}
			} else {
				if( row["boxId"] === boxId ) {
					data.push( row );
				}
			}
			return true;
		} );
		return data;
	}
	
	const getAccessLevel = ( row ) => {
		switch ( row["usertype"] ) {
			case 1 : return ["fas" , "user-lock"]//I18n.get("Root")
			case 2 : return ["fas" , "user-shield"];//I18n.get("Admin")
			case 3 : return ["fas" , "user"];//I18n.get("User")
			default : return "";
		}
		
	}
	
	const formatHourToLocale = ( hourAsString ) => {
		const dateTestLocale = new Date();
		dateTestLocale.setHours( 1 , 0 , 0 );
		if( ! dateTestLocale.toLocaleTimeString( props.locale ).trim().startsWith( "0" ) ) {
			const hour = parseInt( hourAsString.substring(0 , hourAsString.indexOf(" ")));
			const suffix = hour >= 12 ? "PM":"AM";
			return ((hour + 11) % 12 + 1) + " " + suffix;
		}
		return hourAsString;
	}
	
	const renderCell = ( row , headerProps ) => {
		if( headerProps.id === "label" ) {
			if ( row[headerProps.id].customLabel  && row[headerProps.id].customLabel.trim().length > 0 ) {
				return (
					<TableCell 	key={factoryUUID.generate()}
								  align={headerProps.numeric ? 'right' : 'left'}
								  padding={headerProps.disablePadding ? 'none' : 'normal'}
								  className="cell type">
						<div className="type-label">{row[headerProps.id].customLabel}</div>
						<div className="opt-data-label">{row[headerProps.id].optDataLabel}</div>
					</TableCell>
				);
			} else {
				return (
					<TableCell 	key={factoryUUID.generate()}
								  align={headerProps.numeric ? 'right' : 'left'}
								  padding={headerProps.disablePadding ? 'none' : 'normal'}
								  className="cell type">
						<div className="type-label">{row[headerProps.id].typeLabel}</div>
						<div className="opt-data-label">{row[headerProps.id].optDataLabel}</div>
					</TableCell>
				);
			}
		} else if( headerProps.id === "date" ) {
			return (
				<TableCell 	key={factoryUUID.generate()}
							padding={headerProps.disablePadding ? 'none' : 'normal'}
							className="cell date">
							{new Date(row[headerProps.id] * 1000).toLocaleDateString()} {new Date(row[headerProps.id] * 1000).toLocaleTimeString()}
				</TableCell>
			);
		} else if( headerProps.id === "text" ) {
			return (
				<TableCell 	key={factoryUUID.generate()}
							  padding={headerProps.disablePadding ? 'none' : 'normal'}
							  className="cell text">
					{row[headerProps.id]}
				</TableCell>
			);
		} else if( headerProps.id === "error" ) {
			return (
				<TableCell 	key={factoryUUID.generate()}
							  padding={headerProps.disablePadding ? 'none' : 'normal'}
							  className="cell error">
					{renderErrorOnRow(row)}
				</TableCell>
			);
		} else if( headerProps.id === "manual" ) {
			return (
				<TableCell 	key={factoryUUID.generate()}
							  padding={headerProps.disablePadding ? 'none' : 'normal'}
							  className="cell manual">
					{renderManualOnRow(row)}
				</TableCell>
			);
		} else if( headerProps.id === "access_level" ) {
			return (
				<TableCell 	key={factoryUUID.generate()}
							  padding={headerProps.disablePadding ? 'none' : 'normal'}
							  className="cell access_level">
					<FontAwesomeIcon icon={getAccessLevel(row)} className="display-4"/>
				</TableCell>
			);
		} else if( headerProps.id === "user_groups" ) {
			return (
				<TableCell 	key={factoryUUID.generate()}
							  padding={headerProps.disablePadding ? 'none' : 'normal'}
							  className="cell edit">
					<IconButton
						edge="start"
						color="inherit"
						aria-label="groups"
						onClick={()=>{props.onGroupDisplay(row.usergroups)}}
						className={`Icon ${( row.usergroups && row.usergroups?.trim() === "" ) ? "no-click" : "" }`}
					>
						<FontAwesomeIcon icon="fa-solid fa-users-rectangle" className="display-5"/>
						<span className="user-group-count">{( row.usergroups && row.usergroups?.trim() !== "" ) ? row.usergroups?.split(',').length : 0 }</span>
					</IconButton>
				</TableCell>
			);
		} else if( headerProps.id === "action-edit" ) {
			if( row.userid === props.excludeId || row.usertype === 1 ){
				return (
					<TableCell 	key={factoryUUID.generate()}
								  padding={headerProps.disablePadding ? 'none' : 'normal'}
								  className="cell edit">
					</TableCell>
				);
			}
			return (
				<TableCell 	key={factoryUUID.generate()}
							  padding={headerProps.disablePadding ? 'none' : 'normal'}
							  className="cell edit">
					<IconButton
							edge="start"
							color="inherit"
							aria-label="edit"
							onClick={()=>{props.onEdit(row.cognitoid)}}
							className="Icon"
						>
						<FontAwesomeIcon icon={["fas" , "pencil-alt"]} className="display-5"/>
					</IconButton>
				</TableCell>
			);
		} else if( headerProps.id === "action-delete" ) {
			if( row.userid === props.excludeId || row.usertype === 1 ){
				return (
					<TableCell 	key={factoryUUID.generate()}
								  padding={headerProps.disablePadding ? 'none' : 'normal'}
								  className="cell delete">
					</TableCell>
				);
			}
			return (
				<TableCell 	key={factoryUUID.generate()}
							  padding={headerProps.disablePadding ? 'none' : 'normal'}
							  className="cell delete">
					<IconButton
							edge="start"
							color="inherit"
							aria-label="delete"
							onClick={()=>{props.onDelete(row.cognitoid)}}
							className="Icon"
						>
						<FontAwesomeIcon icon={["fas" , "trash"]} className="display-5"/>
					</IconButton>
				</TableCell>
			);
		} else {
			if( headerProps.numeric ) {
				if( headerProps.money ) {
					const formatter = new AmountFormatter(props.locale,  row.currency );
					if ( headerProps.absolute ) {
						return (
							<TableCell 	key={factoryUUID.generate()}
										  align={headerProps.numeric ? 'right' : 'left'}
										  padding={headerProps.disablePadding ? 'none' : 'normal'}
										  className={getCss( headerProps.id , row[headerProps.id])}>
								{formatter.format(Math.abs(row[headerProps.id]))}
							</TableCell>
						);
					} else {
						return (
							<TableCell 	key={factoryUUID.generate()}
										  align={headerProps.numeric ? 'right' : 'left'}
										  padding={headerProps.disablePadding ? 'none' : 'normal'}
										  className={getCss( headerProps.id , row[headerProps.id])}>
								{formatter.format(row[headerProps.id])}
							</TableCell>
						);
					}
				}

				return (
					<TableCell 	key={factoryUUID.generate()}
								align={headerProps.numeric ? 'right' : 'left'}
								padding={headerProps.disablePadding ? 'none' : 'normal'}
								className={getCss( headerProps.id , row[headerProps.id])}>
						{new Intl.NumberFormat( props.locale ).format( row[headerProps.id] )}				
					</TableCell>
				);
			}

			if( headerProps.id === "hour" ) {
				return (
					<TableCell 	key={factoryUUID.generate()}
								padding={headerProps.disablePadding ? 'none' : 'normal'}
								className={getCss( headerProps.id , row[headerProps.id])}>
						{formatHourToLocale( row[headerProps.id] )}	
					</TableCell>
				);
			}

			return (
				<TableCell 	key={factoryUUID.generate()}
							padding={headerProps.disablePadding ? 'none' : 'normal'}
							className={getCss( headerProps.id , row[headerProps.id])}>
					{row[headerProps.id]}	
				</TableCell>
			);
		}
		
	}

	const renderTotalCell = ( data , headCell , formatter ) => {
		if( props.groupBy ) {
			return null;
		} else if( headCell.id === "hopper-value" ) {
			return (
				<TableCell key={factoryUUID.generate()} className="cell"></TableCell>
			);
		} else {
			if( headCell.id === "label" || headCell.id === "id" || headCell.id === "hopper" ) {
				return (
					<TableCell key={factoryUUID.generate()} className={`cell Total ${headCell.id}`}>{I18n.get("Total")}</TableCell>
				);
			} else {
				let total = 0;
				if( headCell.numeric ) {
					data.forEach( (row) => {
						if( row !== null ) {
							total += row[headCell.id];
						}
					} );
					if( !headCell.money ) {
						return (
							<TableCell key={factoryUUID.generate()} className={`cell amount ${headCell.id}`}>
								{new Intl.NumberFormat( props.locale ).format( total )}
							</TableCell>
						);
					} else {
						if ( headCell.absolute ) {
							return (
								<TableCell key={factoryUUID.generate()} className={getCss( headCell.id , total)}>
									{formatter.format(Math.abs( total ))}
								</TableCell>
							);
						} else {
							return (
								<TableCell key={factoryUUID.generate()} className={getCss( headCell.id , total)}>
									{formatter.format( total )}
								</TableCell>
							);
						}
					}
				} 
				return (<TableCell key={factoryUUID.generate()} className={getCss(headCell.id, total)}/>);
			}
		}
	}

	const renderFooter = ( data ) => {
		if( props.noFooter ) {
			return null;
		}
		
		if( rowsPerPage === 0 ) {
			return (
				<TableRow className="footer">
					{(props.header).map((headCell) => (
						renderTotalCell( data , headCell )
					))}
				</TableRow>
			);
		}
		
		if( page >= Math.ceil(props.rows.length / rowsPerPage) - 1 ) {
			const formatter = new AmountFormatter(props.locale,  ( props.currency ) ? props.currency : "EUR" );

			return (
				<TableRow className="footer">
					{(props.header).map((headCell) => (
						renderTotalCell( data , headCell , formatter )
					))}
				</TableRow>
			);
		}
	}

	const formatPagination = ( {from, to, count} ) => {
		return from + "-" + to + " " + I18n.get("of") + " " + count;
	}

	const renderErrorOnRow = ( row ) => {
		return (
			<TableErrorIndicator key={factoryUUID.generate()} row={row} isDarkStyle={props.isDarkStyle}/>
		);
	}
	
	const renderManualOnRow = ( row ) => {
		return (
			<TableManualIndicator key={factoryUUID.generate()} row={row} isDarkStyle={props.isDarkStyle}/>
		);
	}

	const renderRow = ( row, index ) => {
		const keyRow = `table-row-${index}`;
		return (
			<TableRow
			  	hover
			  	className={`${(props.onLineSelection) ? "clickable" : ""}`}
			  	onClick={(evt) => {
				  	if( props.onLineSelection ) {
					  	if( row.hasOwnProperty("hopper") ) {
						  	props.onLineSelection({name:"hopper" , options:[row.hopper]} , null);
					  	}
				  	}
			  	}}
			  	tabIndex={-1}
			  	key={keyRow}
			>
				{(props.header).map((headCell) => (
					renderCell(row , headCell)
				))}
			</TableRow>
		);
	}

	const renderRowHiddenContent = ( row, index ) => {
		const sortByQuantityThenValue = (a, b) => {
			if( a.qty - b.qty === 0 ) {
				return a.value - b.value;
			}
			else return b.qty - a.qty;
		}
		row.details.sort( sortByQuantityThenValue );
		const formatter = new AmountFormatter( props.locale,  row.currency );

		const resolveIcon = type => {
			let icon = fundType.getIcon( fundType.UNKNOWN );
			if( type > 0 ) {
				icon = fundType.getIcon( type );
			}
			return icon;
		};

		const formatQuantity = qty => {
			return ( props.forceAbsoluteInDetails ) ? Math.abs( qty ) : qty;
		}
		
		return (
			<div key={index} className={`hidden-content ${index} closed`}>
				{( props.isDesktop ) ? null : renderMobileHiddenContent( row )}
				<div className="detail-row header-row">
					<div className="detail-column header">{I18n.get("Type")}</div>
					<div className="detail-column header">{I18n.get("Value")}</div>
					<div className="detail-column header">{I18n.get("Quantity")}</div>
					<div className="detail-column header">{I18n.get("Total")}</div>
				</div>
				{row.details.map( (detail, i) => (
					<div key={i} className="detail-row">
						<div className="detail-column row">
							<FontAwesomeIcon icon={resolveIcon( detail.type )} className="display-5" /></div>
						<div className="detail-column row">{detail.value}</div>
						<div className="detail-column row">{formatQuantity( detail.qty )}</div>
						<div className="detail-column row">{formatter.format( detail.value * formatQuantity( detail.qty ) )}</div>
					</div>
				))}
			</div>
		);
	}

	const renderMobileHiddenContent = row => {
		return (
			<>
				<div className="detail-row header-row">
					<div className="detail-column header opt">{I18n.get("Terminal")}</div>
					<div className="detail-column header opt">{I18n.get("Cashier")}</div>
				</div>
				<div className="detail-row header-row">
					<div className="detail-column header opt">{row.terminal}</div>
					<div className="detail-column header opt">{row.cashier}</div>
				</div>
			</>
		);
	}

	const handleClickToggle = boxCssClass => {
		let divs = document.getElementsByClassName("collapsable " + boxCssClass );
		for( let i in divs ) {
			let div = divs[i];
			if( div.classList ) {
				if( div.classList.contains("hidden") ) {
					div.classList.remove("hidden")
				} else {
					div.classList.add("hidden")
				}
			}
		}
		
		let btns = document.getElementsByClassName("toggle icon " + boxCssClass );
		for(let j in btns) {
			let btn = btns[j];
			if( btn.classList ) {
				if( btn.classList.contains("expand") ) {
					btn.classList.remove("expand")
					btn.classList.add("shrink")
				} else {
					btn.classList.remove("shrink")
					btn.classList.add("expand")
				}
			}
		}
	}

	const handleClickDetailsToggle = id => {
		const div = document.querySelector( `.hidden-content.${id}` );
		if( div ) {
			if( div.classList.contains( 'closed' ) ) {
				div.classList.remove( 'closed' );
			} else {
				div.classList.add( 'closed' );
			}
		}
	}

	const renderGroupedByRow = ( rows ) => {
		let returnedRows = [];
		let groupByField = extractGroupByFieldList( rows );
		groupByField.forEach( groupedByField => {
			if( props.groupBy === "label" ) {
				const row = extractRowForBoxId( rows , groupedByField );
				const operationsRowsData = extractRowsForBoxId( rows , groupedByField );
				let subTotal = 0;
				let currency = "EUR";
				operationsRowsData.map( ( rowData ) => {
					subTotal += rowData["amount"];
					currency = rowData["currency"];
					return true;
				} );
				const formatter = new AmountFormatter(props.locale,  currency );
				const classIconToggle = "toggle icon expand " + groupedByField;
				returnedRows.push( 
					<TableRow key={factoryUUID.generate()}>
						<TableCell 	key={factoryUUID.generate()}
									align='center'
									padding='normal'
									className="cell group-by p-0"
									style={{justifyContent:"center" , textAlign:"center!important"}}
									colSpan={props.header.length-1}
									onClick={(evt) => handleClickToggle(groupedByField)}>
							<div className={classIconToggle}/>
							<div className="group-by-container">
								<div className="type-label">{row[props.groupBy].typeLabel}</div>
								<div className="opt-data-label small pb-1" style={{fontSize:"0.6em"}}>{row[props.groupBy].optDataLabel}</div>
							</div>
							<div className="group-by-spacer"></div>
							<div className="sub-total-groupBy" ><span className="small left-large">{I18n.get("SubTotal")} : </span><span className="right-price">{formatter.format(Math.abs(subTotal))}</span></div>
						</TableCell>
					</TableRow>
				);
				
				
				operationsRowsData.forEach(  rowData  => {
					const id = 'hidden-' + factoryUUID.generate();
					let className = "clickable collapsable " + groupedByField ;
					if( operationsRowsData.indexOf(rowData) % 2 === 0 ) {
						className += " even";
					} else {
						className += " odd";
					}
					returnedRows.push( 
						<TableRow key={factoryUUID.generate()} className={className} onClick={(evt) => handleClickDetailsToggle( id )}>
							{props.header.map( headCell => {
								if( headCell.id !== props.groupBy ) {
									return renderCell( rowData , headCell );
								}
								return null;
							})}
						</TableRow>
					);

					returnedRows.push(
						<TableRow key={factoryUUID.generate()} className={"no-border"}>
							<TableCell 	key={factoryUUID.generate()}
										  align='center'
										  padding='normal'
										  className="cell group-by p-0"
										  style={{justifyContent:"center" , textAlign:"center!important"}}
										  colSpan={props.header.length-1}
										  >
								{renderRowHiddenContent( rowData, id )}
							</TableCell>
						</TableRow>
					);
				} );
			} else {
				throw new Error("NOT IMPLEMENTED !!!!!")
			}
		} );
		
		return returnedRows;
	}

	const renderRows = () => {
		let rows = [];
		if( props.groupBy ) {
			if( rowsPerPage > 0 ) {
				return renderGroupedByRow( stableSort(props.rows, getComparator(order, orderBy))
												.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) );

			} else {
				return renderGroupedByRow( stableSort(props.rows, getComparator(order, orderBy)) );
			}
		} else {
			if( rowsPerPage > 0 ) {
				stableSort(props.rows, getComparator(order, orderBy))
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.forEach((row, index) => {
						rows.push( renderRow( row, index ) );
				})
			} else {
				stableSort(props.rows, getComparator(order, orderBy))
					.forEach((row, index) => {
						rows.push( renderRow( row, index ) );
					})
			}
		}
		return rows;
	}

	const renderPagination = () => {
		if(props.noPagination) {
			return null;
		}
		/*
			onChangePage={handleChangePage}
			onChangeRowsPerPage={handleChangeRowsPerPage}
		 */

		return (
			<TableRow>
				<TablePagination
				  rowsPerPageOptions={[5, 10, 25, { label: I18n.get("All"), value: -1 }]}
				  colSpan={props.header.length}
				  count={props.rows.length}
				  rowsPerPage={rowsPerPage}
				  labelDisplayedRows={formatPagination}
				  labelRowsPerPage={I18n.get("Rows per page")}
				  page={page}
				  slotProps={{
					  select: {
						  inputProps: {'aria-label': I18n.get("Rows per page")},
						  native: true,
					  }
				  }}
				  showFirstButton={true}
				  showLastButton={true}
				  onPageChange={handleChangePage}
				  onRowsPerPageChange={handleChangeRowsPerPage}
				  ActionsComponent={TablePaginationActions}
				/>
			</TableRow>
		);
	}
//classes={classes}
	return (
		<TableContainer component={Paper} style={{ overflow: 'hidden' }}>
			<div style={{ display: 'flex', flexDirection: 'column' , overflow: 'hidden' }}>
				<Table className={props.className} aria-label="custom pagination table" style={{ flexShrink: 0 , display:'flex' , flexDirection: 'column' , width: '100%' , flexGrow: 1 , overflow: 'hidden' }}>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						groupBy={props.groupBy}
						onRequestSort={handleRequestSort}
						header={props.header}
						rowCount={props.rows.length}
						style={{ display: 'flex', width: '100%' }}
					/>
					<TableBody style={{ flex: '1 1 auto', overflowY: 'auto' , display: 'flex', width: '100%' , flexDirection: 'column' , maxHeight: '30em' }}/*style={{ display: 'block', maxHeight: '30em', overflowY: 'auto', width: '100%' }}*/>
						{renderRows().map( item => item)}
						{/*emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
						  <TableCell colSpan={6} />
						</TableRow>
					  )*/}
					</TableBody>

					<TableFooter style={{ flexShrink: 0 , display: 'flex', width: '100%' }}/*style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}*/>
						{renderFooter(props.rows)}
						{renderPagination()}
					</TableFooter>
				</Table>
			</div>
		</TableContainer>
	);
}
