import React from 'react';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { I18n } from 'aws-amplify/utils';
import UUID from "../../../Utils/UUID";
import './HopperData.css';

export default function HopperData( props ) {
	const factoryUUID = new UUID();
	const currency = props.deviceAnalyzer.getValueFromData( props.data , "Currency" , `h${props.position}Currency` );
	const value = props.deviceAnalyzer.getValueFromData( props.data , "Amount" , `h${props.position}Value` );
		
	const renderSimpleTableAssociation = ( label , value ) => {
		return (
			<Grid key={factoryUUID.generate()} item xs={12} md={12} lg={12} className={``}>
				<Grid container spacing={0}>
					<Grid item xs={8} md={8} lg={8} className={`simple-table-label`}>
						{I18n.get( label )}
					</Grid>
					<Grid item xs={4} md={4} lg={4} className={`simple-table-value`}>
						{new Intl.NumberFormat( props.locale ).format( value )}
					</Grid>
				</Grid>
			</Grid>
		);
	}
		
	if( props.compact ) {
		return( 
			<React.Fragment>
				<div className={`HopperData-root compact`} onClick={(evt) => {if( props.onSelection !== null && props.onSelection !== undefined ){props.onSelection({name:"hopper" , options:[props.position]} , null)}}}>
					<div className={`identity`}>
						<div className={`position`}>
							<div className={`number`}> {props.position} </div>
						</div>
						<div className={`value`}>
							{new Intl.NumberFormat( props.locale, { style: 'currency', currency: `${( currency !== null && currency !== undefined && currency.length === 3 ) ? currency : "EUR" }` }).format( value )}
						</div>
					</div>
					<div className={`data`}>
						<div className={`line`} title={I18n.get("Lifetime dispensed")}>
							<div className={`icon`}>
								<FontAwesomeIcon icon={['fas', 'coins']} className="display-4 main" />
								<FontAwesomeIcon icon={['fas', 'history']} className="display-5 decorator" />
							</div>
							<div className={`value`}>
								{props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}LifetimeDispensed` )}
							</div>
						</div>
						
						<div className={`line`} title={I18n.get("Coins timeout")}>
							<div className={`icon`}>
								<FontAwesomeIcon icon={['fas', 'coins']} className="display-4 main" />
								<FontAwesomeIcon icon={['fas', 'hourglass-half']} className="display-5 decorator" />
							</div>
							<div className={`value`}>
								{props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}CoinEjectTimeoutExceeded` )}
							</div>
						</div>
						
						<div className={`line`} title={I18n.get("Coins dispensed")}>
							<div className={`icon`}>
								<FontAwesomeIcon icon={['fas', 'coins']} className="display-4 main" />
								<FontAwesomeIcon icon={['fas', 'check']} className="display-5 decorator" />
							</div>
							<div className={`value`}>
								{props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}SuccessDispensed` )}
							</div>
						</div>
						
						<div className={`line`} title={I18n.get("Coins error")}>
							<div className={`icon`}>
								<FontAwesomeIcon icon={['fas', 'coins']} className="display-4 main" />
								<FontAwesomeIcon icon={['fas', 'times']} className="display-5 decorator" />
							</div>
							<div className={`value`}>
								{props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}ErrorDispensed` )}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
	
	return( 
		<React.Fragment>
			<div className={`HopperData-root detailed`}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={12} lg={12} className={`data-block`}>
						<Grid container spacing={0}>
							<Grid item xs={3} md={3} lg={3} className={`hopper-value`}>
								{new Intl.NumberFormat( props.locale, { style: 'currency', currency: `${( currency && currency.length === 3 ) ? currency : "EUR" }` }).format( value )}
							</Grid>
							<Grid item xs={9} md={9} lg={9} className={`hopper-details`}>
								<Grid container spacing={0}>
									{renderSimpleTableAssociation( "Coins accepted" , 	props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}Accepted` ) )}
									{renderSimpleTableAssociation( "Coins sorted" , 		props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}Sorted` ) )}
									{renderSimpleTableAssociation( "Lifetime dispensed" , props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}LifetimeDispensed` ) )}
									{renderSimpleTableAssociation( "Coins overcurrent" , 	props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}Overcurrent` ) )}
									{renderSimpleTableAssociation( "Coins timeout" , 		props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}CoinEjectTimeoutExceeded` ) )}
									{renderSimpleTableAssociation( "Rejected disabled" , 	props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}DeviceDisabled` ) )}
									{renderSimpleTableAssociation( "Rejected inhibit" , 	props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}SerialInhibit` ) )}
									{renderSimpleTableAssociation( "Misroute before" , 	props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}MisroutedBeforeSortCoinType` ) )}
									{renderSimpleTableAssociation( "Misroute after" , 	props.deviceAnalyzer.getValueFromData( props.data , "Int" , `h${props.position}MisroutedAfterSortCoinType` ) )}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</React.Fragment>
	);
}
