import React from 'react';
import { I18n } from 'aws-amplify/utils';
import Grid from '@mui/material/Grid';
import DeviceTechnicalData from '../DeviceTechnicalData/DeviceTechnicalData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {getHopperByConfiguration} from '../../../Models/CpiData';
import BoxSelector from '../BoxSelector/BoxSelector';
import DeviceSelector from '../DeviceSelector/DeviceSelector';
import DeviceDataAnalyzer from '../../../Utils/DeviceDataAnalyzer';
import DeviceTemplate , {Mode} from '../../../Utils/DeviceTemplate';
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import './DeviceSchema.css';
import PayStationFinder from "../../../Utils/PayStationFinder";
const pointInPolygon = require('point-in-polygon');

let device = null;
let dataDisplayer = null;

const Views = {
	FRONT:"",
	BACK:"BACK"
}

export default function DeviceSchema( props ) {
	const [schemaPosition, setSchemaPosition] = React.useState({front:Views.FRONT , back:Views.BACK});
	const [selectedBox, setSelectedBox] = React.useState(null );
	const [selectedDevice, setSelectedDevice] = React.useState(null);

	const handleChange = ( event, newValue ) => {
		if( newValue >= 0 ) {
			clearSelection();
			setSelectedBox( candidateBoxes[newValue] );
			const payStation = getPayStationForBoxId( candidateBoxes[newValue].id );
			setSelectedDevice( getFirstActiveDeviceForBox( payStation.devices ) );
			if( dataDisplayer ) {
				dataDisplayer.onSelectionChanged( null );
			}
		}
	};
	
	const handleChangeDevice = ( event, newValue ) => {
		if( newValue >= 0 ) {
			const payStation = getPayStationForBoxId( selectedBox.id );
			const newDeviceSelected = payStation.devices[newValue];
			if ( newDeviceSelected?.informations?.identity?.model === 'SCR' ) {
				setSchemaPosition( {front:Views.FRONT , back:Views.BACK} );
			}
			clearSelection();
			setSelectedDevice( payStation.devices[newValue] );
			if( dataDisplayer ) {
				dataDisplayer.onSelectionChanged( null );
			}
		}
	};

	const handleClick = ( evt ) => {
		let found = false;
		let part = null;
		Object.keys( registeredShapes ).map( (key) => {
			//manage key priority for z-index layer on event (example input tray on acceptor)
			if( ! found ) {
				let candidateShape = registeredShapes[key];
				if( pointInPolygon( extractRelativePoint( evt.pageX , evt.pageY ) , candidateShape) ) {
					found = true;
					onShapeClicked( key );
					part = key;
				}
			}
			return true;
		} );
		
		clearSelection();
		if( found ) {
			const div = document.getElementById(`${part}-selected-layout`);
			if( div ) {
				div.style.display = "block";
			}
			
			const divBack = document.getElementById(`${part}-BACK-selected-layout`);
			if( divBack ) {
				divBack.style.display = "block";
			}
		} else {
			device = null;
			if( dataDisplayer ) {
				dataDisplayer.onSelectionChanged( null );
			}
		}
	}
	
	const handleMouseMove = ( evt ) => {
		let hovering = false;
		let found = false;
		Object.keys( registeredShapes ).map( (key) => {
			//manage key priority for z-index layer on event (example input tray on acceptor)
			if( ! found ) {
				let candidateShape = registeredShapes[key];
				if( pointInPolygon( extractRelativePoint( evt.pageX , evt.pageY ) , candidateShape) ) {
					onShapeHovered( key );
					hovering = true;
					found = true;
				}
			}
			
			return true;
		} );
		
		if( ! hovering ) {
			const container = document.getElementById(rootId);
			if( container !== null && container !== undefined ) {
				container.style.cursor = "default";
			}
		}
	}
	
	const handleInternalNavigation = ( modules ) => {
		
		if( modules.includes("spine") && schemaPosition.front === Views.FRONT ) {
			toggleSchemas();
			setTimeout( ()=>{
				selectModules( modules );
			} , 1000);
		}
		
		selectModules( modules );
	}
	
	const selectModules = ( modules ) => {
		clearSelection();
		if( modules ) {
			modules.map( (module) => {
				const div = document.getElementById(`${module}-selected-layout`);
				if( div ) {
					div.style.display = "block";
				}
				
				const divBack = document.getElementById(`${module}-BACK-selected-layout`);
				if( divBack ) {
					divBack.style.display = "block";
				}
				return true;
			} );
		}
	}
	
	const handleLocateOnSchema = ( idElement ) => {
		console.log(` to do locate element ${idElement} on schema `);
	}
	
	const toggleSchemas = () => {
		if( schemaPosition.front === Views.FRONT ) {
			setSchemaPosition({front:Views.BACK , back:Views.FRONT});
		} else {
			setSchemaPosition({front:Views.FRONT , back:Views.BACK});
		}
	}
	
	const onShapeClicked = ( key ) => {
		if( key === "r1-2" || key === "r3-4" || key === "r5-6" ) {
			device = {name:"recycler" , options:[key]};
		} else if( key.startsWith("hopper") ) {
			device = {name:"hopper" , options:[parseInt(key.replace("hopper" , ""))]};;
		} else {
			device = {name:key};
		}
		
		if( dataDisplayer ) {
			dataDisplayer.onSelectionChanged( device );
		}
	}
	
	const onShapeHovered = ( key ) => {
		const container = document.getElementById(rootId);
		if( container ) {
			container.style.cursor = "pointer";
		}
	}
	
	const clearSelection = () => {
		try {
			if( model === "BNR" ) {
				document.getElementById("power-selected-layout").style.display = "none";
				document.getElementById("usb-selected-layout").style.display = "none";
				document.getElementById("acceptor-selected-layout").style.display = "none";
				document.getElementById("cashbox-selected-layout").style.display = "none";
				if( variant && variant.includes("BNR3") ) {
					document.getElementById("r1-2-selected-layout").style.display = "none";
					document.getElementById("r3-4-selected-layout").style.display = "none";
				} else if( variant && variant.includes("BNR4") ) {
					document.getElementById("r3-4-selected-layout").style.display = "none";
					document.getElementById("r5-6-selected-layout").style.display = "none";
					document.getElementById("loader-selected-layout").style.display = "none";
				}
				document.getElementById("mainModule-selected-layout").style.display = "none";
				document.getElementById("bundler-selected-layout").style.display = "none";
				document.getElementById("mainModule-BACK-selected-layout").style.display = "none";
				document.getElementById("spine-BACK-selected-layout").style.display = "none";
			} else if ( model === "CLS" ) {
				//single shapes
				if( document.getElementById("hopper1-BACK-selected-layout") ) document.getElementById("hopper1-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper2-BACK-selected-layout") ) document.getElementById("hopper2-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper3-BACK-selected-layout") ) document.getElementById("hopper3-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper4-BACK-selected-layout") ) document.getElementById("hopper4-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper5-BACK-selected-layout") ) document.getElementById("hopper5-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper6-BACK-selected-layout") ) document.getElementById("hopper6-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper7-BACK-selected-layout") ) document.getElementById("hopper7-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper8-BACK-selected-layout") ) document.getElementById("hopper8-BACK-selected-layout").style.display = "none";

				//combined shapes
				if( document.getElementById("hopper1-2-BACK-selected-layout") ) document.getElementById("hopper1-2-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper2-1-BACK-selected-layout") ) document.getElementById("hopper2-1-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper1-2-3-BACK-selected-layout") ) document.getElementById("hopper1-2-3-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper2-1-3-BACK-selected-layout") ) document.getElementById("hopper2-1-3-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper3-2-1-BACK-selected-layout") ) document.getElementById("hopper3-2-1-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper3-2-BACK-selected-layout") ) document.getElementById("hopper3-2-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper2-3-BACK-selected-layout") ) document.getElementById("hopper2-3-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper3-4-BACK-selected-layout") ) document.getElementById("hopper3-4-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper4-3-BACK-selected-layout") ) document.getElementById("hopper4-3-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper5-6-BACK-selected-layout") ) document.getElementById("hopper5-6-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper6-5-BACK-selected-layout") ) document.getElementById("hopper6-5-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper6-5-7-BACK-selected-layout") ) document.getElementById("hopper6-5-7-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper6-7-BACK-selected-layout") ) document.getElementById("hopper6-7-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper7-6-BACK-selected-layout") ) document.getElementById("hopper7-6-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper7-8-BACK-selected-layout") ) document.getElementById("hopper7-8-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper8-7-BACK-selected-layout") ) document.getElementById("hopper8-7-BACK-selected-layout").style.display = "none";
				if( document.getElementById("hopper7-6-8-BACK-selected-layout") ) document.getElementById("hopper7-6-8-BACK-selected-layout").style.display = "none";

			} else if ( model === "SCR" ) {
				document.getElementById("acceptor-selected-layout").style.display = "none";
				document.getElementById("vault-selected-layout").style.display = "none";
				document.getElementById("recyclers-selected-layout").style.display = "none";
				document.getElementById("cashbox-selected-layout").style.display = "none";
				document.getElementById("chassis-selected-layout").style.display = "none";
			}
		} catch ( error ) {
			//silent
		}
	}
	
	const extractRelativePoint = ( x , y ) => {
		const container = document.getElementById(rootId);
		if( container ) {
			const rectangle = container.getBoundingClientRect();
			return [ x - rectangle.left , y - rectangle.top ];
		}
		return [ x , y ];
	}

	const hasDeviceWithTechnicalData = devices => {
		let hasAtLeastOneDeviceValid = false;

		if( devices ) {
			devices.forEach( device => {
				if( device && device.technicalData && device.technicalData.data ) {
					try {
						let parsed = JSON.parse( device.technicalData.data );
						if( Object.keys(parsed).length > 0 ) {
							hasAtLeastOneDeviceValid = true;
						}
					} catch ( error ) {
						console.warn( `excluding invalid attributes` , device.technicalData );
					}
				}
			} );
		}

		return hasAtLeastOneDeviceValid;
	}

	const getPayStationForBoxId = boxId => {
		let found = null;
		if( props.payStations ) {
			props.payStations.forEach( payStation => {
				if( payStation.box && payStation.box.id === boxId ) {
					found = payStation;
				}
			} );
		}
		return found;
	}
	
	const extractTechnicalData = ( device ) => {
		let technicalData = {};
		
		if( device && device.hasOwnProperty( "information" ) ) {
			if( device.information && device.information.hasOwnProperty( "data" ) ) {
				if( device.information.data && device.information.data.hasOwnProperty( "technicalData" ) ) {
					if( device.information.data.technicalData && device.information.data.technicalData.hasOwnProperty( "attributes" ) ) {
						if( device.information.data.technicalData.attributes !== "" ) {
							technicalData = device.information.data.technicalData;
						}
					}
				}
			}
		}
		
		return technicalData;
	}
	
	const extractLatestValuesForDevice = device => {
		let latest = { updatedAt: "0000-00-00T00:00:00.000+00:00" };
		if( device && device.technicalData ) {
			latest = device.technicalData;
		}
		return latest;
	}
	
	const extractReferencesFromData = () => {
		let extracted = [];
		if( data && data.hasOwnProperty("modules") ) {
			for( let i=1 ; i<9 ; i++ ) {
				if( data.modules.hasOwnProperty( `hopper${i}` ) && data.modules[`hopper${i}`].hasOwnProperty( `h${i}SelectorRef` ) ) {
					let candidate = data.modules[`hopper${i}`][`h${i}SelectorRef`];
					if( candidate && candidate.trim() !== "" ) {
						extracted.push( candidate );
					}
				}
			}
		}
		return extracted;
	}
	
	const buildDataStructure = ( device , template , attributes ) => {
		const dataReturned = {};
		if( device ) {
			const latest = extractLatestValuesForDevice( device );
			if( latest && latest.data ) {
				if( device.name.includes( "_BNR_" ) ) {
					addDeviceData( JSON.parse( latest.data ) , dataReturned , template );
					addModulesData( JSON.parse( latest.data ) , dataReturned , template );
				} else if ( device.name.includes( "_CLS_" ) ) {
					addDeviceData( JSON.parse( latest.data ) , dataReturned , template );
					addModulesData( JSON.parse( latest.data ) , dataReturned , template , attributes );
				}  else if ( device.name.includes( "_SCR_" ) ) {
					addDeviceData( JSON.parse( latest.data ) , dataReturned , template );
					addModulesData( JSON.parse( latest.data ) , dataReturned , template , attributes );
					//addAcceptanceData( attributes.denominations , dataReturned , device );
				} else {
					console.log("unknown model " + model);
				}
			} else {
				console.log( "device" , device );
			}

			return dataReturned;
		}
	}
	
	const addDeviceData = ( fullData , objectToLoad , template ) => {
		if( fullData ) {
			let device = {};
			if( template.hasOwnProperty("device") ) {
				if( model.startsWith( "BNR" ) && fullData.hasOwnProperty( "Device" ) ) {
					Object.keys( template.device ).map( ( key ) => {
						let extractedValue = 0;
						switch( key ) {
							case "useHistory":
								if( fullData.Device.hasOwnProperty("UseHistory") ) {
									extractedValue = {
										lastCheckDate:( fullData.Device.UseHistory.hasOwnProperty("CurrentDateTime") ) ? fullData.Device.UseHistory.CurrentDateTime : "0000-00-00T00:00:00.000+00:00",
										voltage: ( fullData.Device.UseHistory.hasOwnProperty("PowerSupplyVoltage") ) ? fullData.Device.UseHistory.PowerSupplyVoltage : 0,
										cycleCount: ( fullData.Device.UseHistory.hasOwnProperty("SystemCycleCount") ) ? fullData.Device.UseHistory.SystemCycleCount : 0,
										temperature: ( fullData.Device.UseHistory.hasOwnProperty("SystemTemperature") ) ? fullData.Device.UseHistory.SystemTemperature : 0,
										operationalSince: ( fullData.Device.UseHistory.hasOwnProperty("TimeSinceOperational") ) ? fullData.Device.UseHistory.TimeSinceOperational : 0,
										totalUpTime: ( fullData.Device.UseHistory.hasOwnProperty("TotalUpTime") ) ? fullData.Device.UseHistory.TotalUpTime : 0,
										upTime: ( fullData.Device.UseHistory.hasOwnProperty("UpTime") ) ? fullData.Device.UseHistory.UpTime : 0,
									};
								}
							break;
							case "deviceAmountNotAvailableCount":
								extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("AmountNotAvailableCount") ) ? fullData.Device.BillDispenseHistory.AmountNotAvailableCount : 0;
							break;
							case "deviceBillNotAvailableCount":
								extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("BillNotAvailableCount") ) ? fullData.Device.BillDispenseHistory.BillNotAvailableCount : 0;
							break;
							case "deviceBillRequestedCount":
								extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("BillRequestedCount") ) ? fullData.Device.BillDispenseHistory.BillRequestedCount : 0;
							break;
							case "deviceDirectFromLoaderCount":
								extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("DirectFromLoaderCount") ) ? fullData.Device.BillDispenseHistory.DirectFromLoaderCount : 0;
							break;
							case "deviceBillErrorCount":
								extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("BillErrorCount") ) ? fullData.Device.FailureHistory.BillErrorCount : 0;
							break;
							case "deviceBillJamCount":
								extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("BillJamCount") ) ? fullData.Device.FailureHistory.BillJamCount : 0;
							break;
							case "deviceHardwareFailureCount":
								extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("HardwareFailureCount") ) ? fullData.Device.FailureHistory.HardwareFailureCount : 0;
							break;
							case "deviceMissingModuleCount":
								extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("MissingModuleCount") ) ? fullData.Device.FailureHistory.MissingModuleCount : 0;
							break;
							case "deviceResetWithCoverOpenCount":
								extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("ResetWithCoverOpenCount") ) ? fullData.Device.FailureHistory.ResetWithCoverOpenCount : 0;
							break;
							case "deviceResetWithInterlockOpenCount":
								extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("ResetWithInterlockOpenCount") ) ? fullData.Device.FailureHistory.ResetWithInterlockOpenCount : 0;
							break;
							case "deviceTransportErrorCount":
								extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("TransportErrorCount") ) ? fullData.Device.FailureHistory.TransportErrorCount : 0;
							break;
							default:
							break;
						}
						device[key] = extractedValue;
						
						return true;
					} );
				} else if ( model.startsWith( "CLS" ) ) {
					let extractedValue = 0;
					Object.keys( template.device ).map( ( key ) => {
						if( fullData ) {
							switch( key ) {
								case "useHistory":
									extractedValue = {
										lastCheckDate:( fullData.hasOwnProperty("updatedAt") ) ? fullData.updatedAt : new Date().toISOString(),
									};
								break;
								case "coinOutputTrayFull":
									extractedValue = ( fullData.hasOwnProperty("CoinOutputTrayFull") && fullData.CoinOutputTrayFull === "true" ) ? true : false ;
								break;
								case "coinOutputTrayPresent":
									extractedValue = ( fullData.hasOwnProperty("CoinOutputTrayPresent") && fullData.CoinOutputTrayPresent === "true" ) ? true : false ;
								break;
								case "lowerCtcPowered":
									extractedValue = ( fullData.hasOwnProperty("LowerCtcPowered") && fullData.LowerCtcPowered === "true" ) ? true : false ;
								break;
								case "upperCtcPowered":
									extractedValue = ( fullData.hasOwnProperty("UpperCtcPowered") && fullData.UpperCtcPowered === "true" ) ? true : false ;
								break;
								case "checksumErrorDatablock":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("ChecksumErrorDatablock") ) ? fullData.Statistic.ChecksumErrorDatablock : 0;
								break;
								case "checksumErrorFirmware":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("ChecksumErrorFirmware") ) ? fullData.Statistic.ChecksumErrorFirmware : 0;
								break;
								case "checksumErrorRAM":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("ChecksumErrorRAM") ) ? fullData.Statistic.ChecksumErrorRAM : 0;
								break;
								case "coinJam":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CoinJam") ) ? fullData.Statistic.CoinJam : 0;
								break;
								case "coinOversize":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CoinOversize") ) ? fullData.Statistic.CoinOversize : 0;
								break;
								case "coinTooBig":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CoinTooBig") ) ? fullData.Statistic.CoinTooBig : 0;
								break;
								case "coinUndersize":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CoinUndersize") ) ? fullData.Statistic.CoinUndersize : 0;
								break;
								case "coinWasSlug":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CoinWasSlug") ) ? fullData.Statistic.CoinWasSlug : 0;
								break;
								case "creditSensorBlocked":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CreditSensorBlocked") ) ? fullData.Statistic.CreditSensorBlocked : 0;
								break;
								case "daughterBoardNotConnected":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("DaughterBoardNotConnected") ) ? fullData.Statistic.DaughterBoardNotConnected : 0;
								break;
								case "deviceDisabled":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("DeviceDisabled") ) ? fullData.Statistic.DeviceDisabled : 0;
								break;
								case "diameterMeasurementErrorTriggerCalculation":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("DiameterMeasurementErrorTriggerCalculation") ) ? fullData.Statistic.DiameterMeasurementErrorTriggerCalculation : 0;
								break;
								case "diameterTimingMeasurementMisfit":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("DiameterTimingMeasurementMisfit") ) ? fullData.Statistic.DiameterTimingMeasurementMisfit : 0;
								break;
								case "errorStateTransitions":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("ErrorStateTransitions") ) ? fullData.Statistic.ErrorStateTransitions : 0;
								break;
								case "fullCoinOutputTrayConditions":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("FullCoinOutputTrayConditions") ) ? fullData.Statistic.FullCoinOutputTrayConditions : 0;
								break;
								case "hoppersCommunicationsErrors":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("HoppersCommunicationsErrors") ) ? fullData.Statistic.HoppersCommunicationsErrors : 0;
								break;
								case "hostCommunicationsErrors":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("HostCommunicationsErrors") ) ? fullData.Statistic.HostCommunicationsErrors : 0;
								break;
								case "measurementTimeout":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("MeasurementTimeout") ) ? fullData.Statistic.MeasurementTimeout : 0;
								break;
								case "missingJammedCoin":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("MissingJammedCoin") ) ? fullData.Statistic.MissingJammedCoin : 0;
								break;
								case "operatingLifetime":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("OperatingLifetime") ) ? fullData.Statistic.OperatingLifetime : 0;
								break;
								case "opticalSensorBlocked":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("OpticalSensorBlocked") ) ? fullData.Statistic.OpticalSensorBlocked : 0;
								break;
								case "performances":
									extractedValue = {
										indicatorAcceptanceRate:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("AcceptanceRate") ) ? fullData.Statistic.PerformanceIndicator.AcceptanceRate : 0,
										indicatorAcceptedCoins:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("AcceptedCoins") ) ? fullData.Statistic.PerformanceIndicator.AcceptedCoins : 0,
										indicatorAcceptorJams:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("AcceptorJams") ) ? fullData.Statistic.PerformanceIndicator.AcceptorJams : 0,
										indicatorAcceptorProcessedCoins:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("AcceptorProcessedCoins") ) ? fullData.Statistic.PerformanceIndicator.AcceptorProcessedCoins : 0,
										indicatorAccountancyErrorRate:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("AccountancyErrorRate") ) ? fullData.Statistic.PerformanceIndicator.AccountancyErrorRate : 0,
										indicatorDispenserJams:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("DispenserJams") ) ? fullData.Statistic.PerformanceIndicator.DispenserJams : 0,
										indicatorJamRate:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("JamRate") ) ? fullData.Statistic.PerformanceIndicator.JamRate : 0,
										indicatorMiscreditedCoins:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("MiscreditedCoins") ) ? fullData.Statistic.PerformanceIndicator.MiscreditedCoins : 0,
										indicatorMissortedCoins:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("MissortedCoins") ) ? fullData.Statistic.PerformanceIndicator.MissortedCoins : 0,
										indicatorMissortingErrorRate:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("MissortingErrorRate") ) ? fullData.Statistic.PerformanceIndicator.MissortingErrorRate : 0,
										indicatorPayInVolumeRate:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("PayInVolumeRate") ) ? fullData.Statistic.PerformanceIndicator.PayInVolumeRate : 0,
										indicatorSortedCoins:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("SortedCoins") ) ? fullData.Statistic.PerformanceIndicator.SortedCoins : 0,
										indicatorSystemProcessedCoins:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("SystemProcessedCoins") ) ? fullData.Statistic.PerformanceIndicator.SystemProcessedCoins : 0,
										indicatorTransactionMisrouteRate:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PerformanceIndicator") && fullData.Statistic.PerformanceIndicator.hasOwnProperty("TransactionMisrouteRate") ) ? fullData.Statistic.PerformanceIndicator.TransactionMisrouteRate : 0,
									};
								break;
								case "sortedCoin":
									extractedValue = {
										sortedCoinTotal:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortedCoin") && fullData.Statistic.SortedCoin.hasOwnProperty("Total") ) ? fullData.Statistic.SortedCoin.Total : 0,
										sortedCoinTotalWithMisroutedCoins:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortedCoin") && fullData.Statistic.SortedCoin.hasOwnProperty("TotalWithMisroutedCoins") ) ? fullData.Statistic.SortedCoin.TotalWithMisroutedCoins : 0,
										error:{
											sortingPath1SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath1SensorError") ) ? fullData.Statistic.SortingPath1SensorError : 0,
											sortingPath2SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath2SensorError") ) ? fullData.Statistic.SortingPath2SensorError : 0,
											sortingPath3SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath3SensorError") ) ? fullData.Statistic.SortingPath3SensorError : 0,
											sortingPath4SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath4SensorError") ) ? fullData.Statistic.SortingPath4SensorError : 0,
											sortingPath5SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath5SensorError") ) ? fullData.Statistic.SortingPath5SensorError : 0,
											sortingPath6SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath6SensorError") ) ? fullData.Statistic.SortingPath6SensorError : 0,
											sortingPath7SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath7SensorError") ) ? fullData.Statistic.SortingPath7SensorError : 0,
											sortingPath8SensorError:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("SortingPath8SensorError") ) ? fullData.Statistic.SortingPath8SensorError : 0
										}
									};
								break;
								case "power":
									extractedValue = {
										powerUps:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PowerUps") ) ? fullData.Statistic.PowerUps : 0,
										powerUpsCozIndependentWatchdogReset:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PowerUpsCozIndependentWatchdogReset") ) ? fullData.Statistic.PowerUpsCozIndependentWatchdogReset : 0,
										powerUpsCozLowPowerManagementReset:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PowerUpsCozLowPowerManagementReset") ) ? fullData.Statistic.PowerUpsCozLowPowerManagementReset : 0,
										powerUpsCozPorPdrReset:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PowerUpsCozPorPdrReset") ) ? fullData.Statistic.PowerUpsCozPorPdrReset : 0,
										powerUpsCozResetFromNrstPin:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PowerUpsCozResetFromNrstPin") ) ? fullData.Statistic.PowerUpsCozResetFromNrstPin : 0,
										powerUpsCozSoftwareReset:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PowerUpsCozSoftwareReset") ) ? fullData.Statistic.PowerUpsCozSoftwareReset : 0,
										powerUpsCozWindowWatchdogReset:( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PowerUpsCozWindowWatchdogReset") ) ? fullData.Statistic.PowerUpsCozWindowWatchdogReset : 0,
									};
								break;
								case "payInTransactionVolume" :
									extractedValue = {
										"1To4Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("1Coins4") ) ? fullData.Statistic.PayInTransaction["1Coins4"] : 0,
										"5To9Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("5Coins9") ) ? fullData.Statistic.PayInTransaction["5Coins9"] : 0,
										"10To19Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("10Coins19") ) ? fullData.Statistic.PayInTransaction["10Coins19"] : 0,
										"20To29Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("20Coins29") ) ? fullData.Statistic.PayInTransaction["20Coins29"] : 0,
										"30To39Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("30Coins39") ) ? fullData.Statistic.PayInTransaction["30Coins39"] : 0,
										"40To49Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("40Coins49") ) ? fullData.Statistic.PayInTransaction["40Coins49"] : 0,
										"50To99Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("50Coins99") ) ? fullData.Statistic.PayInTransaction["50Coins99"] : 0,
										"100To199Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("100Coins199") ) ? fullData.Statistic.PayInTransaction["100Coins199"] : 0,
										"200To299Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("200Coins299") ) ? fullData.Statistic.PayInTransaction["200Coins299"] : 0,
										"300To399Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("300Coins399") ) ? fullData.Statistic.PayInTransaction["300Coins399"] : 0,
										"400To499Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("400Coins499") ) ? fullData.Statistic.PayInTransaction["400Coins499"] : 0,
										"500To999Coins":( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("PayInTransaction") && fullData.Statistic.PayInTransaction.hasOwnProperty("500Coins999") ) ? fullData.Statistic.PayInTransaction["500Coins999"] : 0,
									};
								break;
								case "transportBeltMotorMalfunction":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("TransportBeltMotorMalfunction") ) ? fullData.Statistic.TransportBeltMotorMalfunction : 0;
								break;
								case "transportBeltMotorStarts":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("TransportBeltMotorStarts") ) ? fullData.Statistic.TransportBeltMotorStarts : 0;
								break;
								case "trashdoorMotorStarts":
									extractedValue = ( fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("TrashdoorMotorStarts") ) ? fullData.Statistic.TrashdoorMotorStarts : 0;
								break;
								default:
								break;
							}
						}	
						device[key] = extractedValue;
						return true;
					} );			
					 
				} else if ( model.startsWith( "SCR" ) ) {
					Object.keys( template.device ).map( ( key ) => {
						let extractedValue = 0;
						switch( key ) {
							case "useHistory":
								extractedValue = {
									lastCheckDate:( fullData.updatedAt ) ? fullData.updatedAt : new Date().toISOString(),
								};
							break;
							case "motorStarts":
								extractedValue = ( fullData.hasOwnProperty("AcceptorLifeTime") && fullData.AcceptorLifeTime.hasOwnProperty("MotorStarts") ) ? fullData.AcceptorLifeTime.MotorStarts : 0;
							break;
							case "securityRejections":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("SecurityRejections") ) ? fullData.AcceptorPerf.SecurityRejections : 0;
							break;
							case "operatingHours":
								extractedValue = ( fullData.hasOwnProperty("AcceptorLifeTime") && fullData.AcceptorLifeTime.hasOwnProperty("OperatingHours") ) ? fullData.AcceptorLifeTime.OperatingHours : 0;
							break;
							case "outOfOrder":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("OutOfOrderConditions") ) ? fullData.AcceptorPerf.OutOfOrderConditions : 0;
							break;
							case "outOfService":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("OutOfServiceConditions") ) ? fullData.AcceptorPerf.OutOfServiceConditions : 0;
							break;
							case "hostResets":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("HostResets") ) ? fullData.AcceptorPerf.HostResets : 0;
							break;
							case "resets":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("Resets") ) ? fullData.AcceptorPerf.Resets : 0;
							break;
							case "recognitionRejection":
								extractedValue = ( fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("RecognitionRejections") ) ? fullData.AcceptorQP.RecognitionRejections : 0;
							break;
							case "documentDisabledRejections":
								extractedValue = ( fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("DocumentDisabledRejections") ) ? fullData.AcceptorQP.DocumentDisabledRejections : 0;
							break;
							case "orientationDisabledRejections":
								extractedValue = ( fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("OrientationDisabledRejections") ) ? fullData.AcceptorQP.OrientationDisabledRejections : 0;
							break;
							case "hostReturnDocumentRejections":
								extractedValue = ( fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("HostReturnDocumentRejections") ) ? fullData.AcceptorQP.HostReturnDocumentRejections : 0;
							break;
							case "barcodesDecoded":
								extractedValue = ( fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("BarcodesDecoded") ) ? fullData.AcceptorQP.BarcodesDecoded : 0;
							break;
							case "docsRejectForOtherReason":
								extractedValue = ( fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("DocsRejectForOtherReason") ) ? fullData.AcceptorQP.DocsRejectForOtherReason : 0;
							break;
							case "escrowTimeoutRejections":
								extractedValue = ( fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("EscrowTimeoutRejections") ) ? fullData.AcceptorQP.EscrowTimeoutRejections : 0;
							break;
							case "docsThatFailedToReachEscrowPosition":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("DocsThatFailedToReachEscrowPosition") ) ? fullData.AcceptorPerf.DocsThatFailedToReachEscrowPosition : 0;
							break;
							case "allTypesOfJams":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("AllTypesOfJams") ) ? fullData.AcceptorPerf.AllTypesOfJams : 0;
							break;
							case "jamRecoveryEfforts":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("JamRecoveryEfforts") ) ? fullData.AcceptorPerf.JamRecoveryEfforts : 0;
							break;
							case "outOfServiceForJammedOnReset":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("OutOfServiceForJammedOnReset") ) ? fullData.AcceptorPerf.OutOfServiceForJammedOnReset : 0;
							break;
							case "flashDownloadAttempts":
								extractedValue = ( fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("FlashDownloadAttempts") ) ? fullData.AcceptorPerf.FlashDownloadAttempts : 0;
							break;
							default:
							break;
						}
						device[key] = extractedValue;
						
						return true;
					} );
				}
			}
			
			objectToLoad.device = device;
		}
	};
	
	const addModulesData = ( fullData , objectToLoad , template , attributes ) => {
		if( fullData ) {
			let modules = {};
			if( template.hasOwnProperty("modules") ) {
				Object.keys( template.modules ).map( (key) => {
					modules[key] = buildModuleData( key , fullData , template , attributes )
					return true;
				} );
			}

			objectToLoad.modules = modules;
		}
	}
	
	const addAcceptanceData = ( denominations , dataReturned , device ) => {
		const groupedByValue = [];
		denominations.map( ( candidate ) => {
			if( candidate ) {
				let found = null;
				groupedByValue.map( ( existing ) => {
					if( existing.value === candidate.value && existing.currency === candidate.currency ) {
						found = existing;
					}
					return true;
				} );
				
				if( ! found ) {
					found = { 
						value:candidate.value ,
						currency:candidate.currency,
						compatibility:[],
						serie:[],
						type:[],
						version:[],
						recyclable: extractRecycler( candidate.value , candidate.currency , device ) > 0 ,
						recycler: extractRecycler( candidate.value , candidate.currency , device )
					};
					groupedByValue.push( found );
				}
				
				if( ! found.compatibility.includes( candidate["variant_compatibility"] ) ) found.compatibility.push( candidate["variant_compatibility"] );
				if( ! found.serie.includes( candidate["variant_serie"] ) ) found.serie.push( candidate["variant_serie"] );
				if( ! found.type.includes( candidate["variant_type"] ) ) found.type.push( candidate["variant_type"] );
				if( ! found.version.includes( candidate["variant_version"] ) ) found.version.push( candidate["variant_version"] );
			}
			return true;
		} );
		dataReturned.denominations = groupedByValue;
	}
	
	const extractRecycler = ( value , currency , device ) => {
		let index = -1;
		if( device && device.recyclable ) {
			device.recyclable.map( ( recycled ) => {
				if( recycled.currency === currency && recycled.value === value ) {
					index = device.recyclable.indexOf( recycled ) + 1 ;
				}
				return true;
			} );
		}
		return index;
	}
	
	const buildModuleData = ( moduleName , fullData , template , attributes ) => {
		switch( moduleName ) {
			case "mainModule" : return buildMainModuleData( moduleName , fullData.Module , template );
			case "spine" : 		return buildSpineData( moduleName , fullData.Module , template );
			case "cashbox" : 	return ( fullData && fullData.Module && fullData.Module.Cashbox) ? buildCashboxData( moduleName , fullData.Module.Cashbox , template ) : buildCashboxData( moduleName , fullData , template , attributes );
			case "recycler1" : //fall through
			case "recycler2" :
			case "recycler3" :
			case "recycler4" :
			case "recycler5" :
			case "recycler6" :  return buildRecyclerData( moduleName , fullData , template );
			case "hopper1" : //fall through
			case "hopper2" : 
			case "hopper3" : 
			case "hopper4" : 
			case "hopper5" : 
			case "hopper6" : 
			case "hopper7" : 
			case "hopper8" : return buildHopperData( moduleName , fullData , template , attributes );
			case "centrifuge":
				return {
					centrifugeMotorStarts:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CentrifugeMotorMalfunction") ) ? fullData.Statistic.CentrifugeMotorMalfunction : 0,
					centrifugeMotorMalfunction:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("CentrifugeMotorStarts") ) ? fullData.Statistic.CentrifugeMotorStarts : 0,
				};
			case "conveyor":
				return {
					conveyorMotorStarts:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("ConveyorMotorStarts") ) ? fullData.Statistic.ConveyorMotorStarts : 0,
					conveyorMotorErrors:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("ConveyorMotorErrors") ) ? fullData.Statistic.ConveyorMotorErrors : 0,
				};
			case "recyclers":
				return {
					notesStackedToRecyclers:( fullData && fullData.hasOwnProperty("AcceptorLifeTime") && fullData.AcceptorLifeTime.hasOwnProperty("NotesStackedToRecycler") ) ? fullData.AcceptorLifeTime.NotesStackedToRecycler : 0,
					outOfServiceForRecyclerOpened:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("OutOfServiceForJammedOnReset") ) ? fullData.AcceptorPerf.OutOfServiceForJammedOnReset : 0
				};
			case "acceptor":
				if( fullData && model === "SCR" ) {
					return {
						notesRecognized:( fullData && fullData.hasOwnProperty("AcceptorLifeTime") && fullData.AcceptorLifeTime.hasOwnProperty("NotesRecognized") ) ? fullData.AcceptorLifeTime.NotesRecognized : 0,
						docInsertedWhileDisabled:( fullData && fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("DocsInsertedWhileDisabled") ) ? fullData.AcceptorQP.DocsInsertedWhileDisabled : 0,
						docInsertedWhileBusy:( fullData && fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("DocsInsertedWhileRecyclerBusy") ) ? fullData.AcceptorQP.DocsInsertedWhileRecyclerBusy : 0,
						notesStacked:( fullData && fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("DocsStacked") ) ? fullData.AcceptorQP.DocsStacked : 0,
						fastFeedErrorReject:( fullData && fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("FastFeedErrorRejections") ) ? fullData.AcceptorQP.FastFeedErrorRejections : 0,
						notesValidated:( fullData && fullData.hasOwnProperty("AcceptorLifeTime") && fullData.AcceptorLifeTime.hasOwnProperty("NotesValidated") ) ? fullData.AcceptorLifeTime.NotesValidated : 0,
						notesDispensed:( fullData && fullData.hasOwnProperty("AcceptorLifeTime") && fullData.AcceptorLifeTime.hasOwnProperty("NotesDispensed") ) ? fullData.AcceptorLifeTime.NotesDispensed : 0,
						outOfServiceForJammed:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("OutOfServiceForJammed") ) ? fullData.AcceptorPerf.OutOfServiceForJammed : 0,
						escrowPositionFailure:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("DocsThatFailedToReachEscrowPosition") ) ? fullData.AcceptorPerf.DocsThatFailedToReachEscrowPosition : 0,
						calibrations:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("Calibrations") ) ? fullData.AcceptorPerf.Calibrations : 0,
						notesTooSmall:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("DocsLessThanMiniAllowableLength") ) ? fullData.AcceptorPerf.DocsLessThanMiniAllowableLength : 0,
						notesTooBig:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("DocsGreaterThanMaxiAllowableLength") ) ? fullData.AcceptorPerf.DocsGreaterThanMaxiAllowableLength : 0,
						docRejectedForOtherReason:( fullData && fullData.hasOwnProperty("AcceptorQP") && fullData.AcceptorQP.hasOwnProperty("DocsRejectForOtherReason") ) ? fullData.AcceptorQP.DocsRejectForOtherReason : 0,
					};
				} 
				return {
					acceptorCommunicationsErrors:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("AcceptorCommunicationsErrors") ) ? fullData.Statistic.AcceptorCommunicationsErrors : 0,
					acceptorKnownCoinsRejected:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("AcceptorKnownCoinsRejected") ) ? fullData.Statistic.AcceptorKnownCoinsRejected : 0,
					acceptorDeviceErrors:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("AcceptorDeviceErrors") ) ? fullData.Statistic.AcceptorDeviceErrors : 0,
					acceptorUnknownCoinsRejected:( fullData && fullData.hasOwnProperty("Statistic") && fullData.Statistic.hasOwnProperty("AcceptorUnknownCoinsRejected") ) ? fullData.Statistic.AcceptorUnknownCoinsRejected : 0,
				};
			default: return null;
		}
	}
	
	const buildMainModuleData = ( moduleName , fullData , template ) => {
		let sensorList = ( fullData.MainModule.hasOwnProperty("MaintenanceInfo") && fullData.MainModule.MaintenanceInfo.hasOwnProperty("MaintenanceSensorStatus") ) ? fullData.MainModule.MaintenanceInfo.MaintenanceSensorStatus : []; 
		let sensorBundlerList = ( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("MaintenanceSensorStatus") ) ? fullData.Bundler.MaintenanceInfo.MaintenanceSensorStatus : []; 
		
		return {
			sensors : {
				196870:extractSensorValue( sensorBundlerList , 196870),
				196873:extractSensorValue( sensorList , 196873),
				196874:extractSensorValue( sensorList , 196874),
				198144:extractSensorValue( sensorList , 198144),
				196865:extractSensorValue( sensorList , 196865),
				196866:extractSensorValue( sensorList , 196866),
				196867:extractSensorValue( sensorList , 196867),
				196868:extractSensorValue( sensorList , 196868),
				196869:extractSensorValue( sensorList , 196869),
				196864:extractSensorValue( sensorList , 196864),
				196871:extractSensorValue( sensorList , 196871),
				196872:extractSensorValue( sensorList , 196872),
				196875:extractSensorValue( sensorList , 196875),
			},
			bundler: {
				cyclesSinceLastMaintenance:( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("CycleSinceLastMaintenance") ) ? fullData.Bundler.MaintenanceInfo.CycleSinceLastMaintenance : 0,
				maintenaceBundlerSlippage:( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("MaintenanceBundlerSlippage") ) ? fullData.Bundler.MaintenanceInfo.MaintenanceBundlerSlippage/10 : 0,
				maintenanceInterval:( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("MaintenanceInterval") ) ? fullData.Bundler.MaintenanceInfo.MaintenanceInterval : 0
			}
		};
	}
	
	const buildSpineData = ( moduleName , fullData , template ) => {
		let sensorList = ( fullData.Routing.hasOwnProperty("MaintenanceInfo") && fullData.Routing.MaintenanceInfo.hasOwnProperty("MaintenanceSensorStatus") ) ? fullData.Routing.MaintenanceInfo.MaintenanceSensorStatus : []; 

		return {
			sensors : {
				198402:extractSensorValue( sensorList , 198402),
				198403:extractSensorValue( sensorList , 198403),
				198404:extractSensorValue( sensorList , 198404)
			}
		};
	}
	
	const buildCashboxData = ( moduleName , fullData , template , attributes) => {
		if( fullData && model === "SCR" ) {
			return {
				notesStackedToCashbox:( fullData && fullData.hasOwnProperty("AcceptorLifeTime") && fullData.AcceptorLifeTime.hasOwnProperty("NotesStackedToCashbox") ) ? fullData.AcceptorLifeTime.NotesStackedToCashbox : 0,
				removed:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("CassetteRemovedConditions") ) ? fullData.AcceptorPerf.CassetteRemovedConditions : 0,
				full:( fullData && fullData.hasOwnProperty("AcceptorPerf") && fullData.AcceptorPerf.hasOwnProperty("CassetteFullConditions") ) ? fullData.AcceptorPerf.CassetteFullConditions : 0,
				capacity: ( attributes && attributes.cashbox && attributes.cashbox.capacity ) ? attributes.cashbox.capacity : 0
			};
		}
		return {
			cbLevelChangeCount:( fullData.hasOwnProperty("BillCassetteExchangeHistory") && fullData.BillCassetteExchangeHistory.hasOwnProperty("LevelChangeCount") ) ? fullData.BillCassetteExchangeHistory.LevelChangeCount : 0,
			cbModuleChangedCount:( fullData.hasOwnProperty("BillCassetteExchangeHistory") && fullData.BillCassetteExchangeHistory.hasOwnProperty("ModuleChangedCount") ) ? fullData.BillCassetteExchangeHistory.ModuleChangedCount : 0,
			cbModuleFullCount:( fullData.hasOwnProperty("BillCassetteExchangeHistory") && fullData.BillCassetteExchangeHistory.hasOwnProperty("ModuleFullCount") ) ? fullData.BillCassetteExchangeHistory.ModuleFullCount : 0,
			cbModuleRemovalCount:( fullData.hasOwnProperty("BillCassetteExchangeHistory") && fullData.BillCassetteExchangeHistory.hasOwnProperty("ModuleRemovalCount") ) ? fullData.BillCassetteExchangeHistory.ModuleRemovalCount : 0,
			cbInternalResetCount:( fullData.hasOwnProperty("RestartHistory") && fullData.RestartHistory.hasOwnProperty("InternalResetCount") ) ? fullData.RestartHistory.InternalResetCount : 0,
			cbPowerUpCount:( fullData.hasOwnProperty("RestartHistory") && fullData.RestartHistory.hasOwnProperty("PowerUpCount") ) ? fullData.RestartHistory.PowerUpCount : 0,
			cbBillErrorCount:( fullData.hasOwnProperty("FailureHistory") && fullData.FailureHistory.hasOwnProperty("Failure") && fullData.FailureHistory.Failure.hasOwnProperty("BillErrorCount") ) ? fullData.FailureHistory.Failure.BillErrorCount : 0,
			cbBillJamCount:( fullData.hasOwnProperty("FailureHistory") && fullData.FailureHistory.hasOwnProperty("Failure") && fullData.FailureHistory.Failure.hasOwnProperty("BillJamCount") ) ? fullData.FailureHistory.Failure.BillJamCount : 0,
			cbHardwareFailureCount:( fullData.hasOwnProperty("FailureHistory") && fullData.FailureHistory.hasOwnProperty("Failure") && fullData.FailureHistory.Failure.hasOwnProperty("HardwareFailureCount") ) ? fullData.FailureHistory.Failure.HardwareFailureCount : 0,
			cbTransportErrorCount:( fullData.hasOwnProperty("FailureHistory") && fullData.FailureHistory.hasOwnProperty("Failure") && fullData.FailureHistory.Failure.hasOwnProperty("TransportErrorCount") ) ? fullData.FailureHistory.Failure.TransportErrorCount : 0,
			cbNotArmedCount:( fullData.hasOwnProperty("FailureHistory") && fullData.FailureHistory.hasOwnProperty("NotArmedCount") ) ? fullData.FailureHistory.NotArmedCount : 0
		};
	}
	
	const buildRecyclerData = ( moduleName , fullData , template ) => {
		let reduceIndex = 0;
		if( variant && variant.includes("BNR4") ) {
			reduceIndex = 2;
		}
		let indexRecycler = moduleName.replace( "recycler" , "" );
		let sensorId = Object.keys( template.modules[moduleName].sensors )[0];
		let recycler = fullData.Module.Recycler.Id[indexRecycler];
		let dispenseHistory = fullData.Device.BillDispenseHistory.CashTypeDispenseHistory[parseInt(indexRecycler , 10) -  (reduceIndex + 1)];
		let sensorList = ( recycler.hasOwnProperty("MaintenanceInfo") && recycler.MaintenanceInfo.hasOwnProperty("MaintenanceSensorStatus") ) ? recycler.MaintenanceInfo.MaintenanceSensorStatus : []; 
		let returned = {
			sensors : {}
		}

		returned.sensors[`${sensorId}`] = extractSensorValue( sensorList , parseInt( sensorId , 10 ) );
		returned[`r${indexRecycler}Currency`] = ( dispenseHistory && dispenseHistory.hasOwnProperty("CurrencyCode") ) ? dispenseHistory.CurrencyCode : "EUR";
		returned[`r${indexRecycler}Value`] = ( dispenseHistory && dispenseHistory.hasOwnProperty("Value") ) ? dispenseHistory.Value : 0;
		returned[`r${indexRecycler}Dispensed`] = ( recycler && recycler.hasOwnProperty("MaintenanceInfo") && recycler.MaintenanceInfo.hasOwnProperty("") ) ? recycler.MaintenanceInfo.CycleSinceLastMaintenance : 0;
		returned[`r${indexRecycler}CyclesSinceLastMaintenance`] = ( recycler && recycler.hasOwnProperty("MaintenanceInfo") && recycler.MaintenanceInfo.hasOwnProperty("CycleSinceLastMaintenance") ) ? recycler.MaintenanceInfo.CycleSinceLastMaintenance : 0;
		returned[`r${indexRecycler}MaintenanceInterval`] = ( recycler && recycler.hasOwnProperty("MaintenanceInfo") && recycler.MaintenanceInfo.hasOwnProperty("MaintenanceInterval") ) ? recycler.MaintenanceInfo.MaintenanceInterval : 0;

		return returned;
	}
	
	const buildHopperData = ( moduleName , fullData , template , attributes ) => {
		let indexRecycler = moduleName.replace( "hopper" , "" );
		//let dispenseHistory = attributes.denominations[parseInt(indexRecycler , 10) - 1];
		let dataBlock = `${ (attributes && attributes.hasOwnProperty("acceptor") && attributes.acceptor.hasOwnProperty("datablock") ) ? attributes.acceptor.datablock : "EUR" }`;
		if( dataBlock.startsWith( "R0C510EU" ) ) {
			dataBlock = "EUR_48278";
		}
		let returned = {}
		
		//let hopper = getHopperByConfiguration( indexRecycler , `${ (attributes !== null && attributes !== undefined && attributes.hasOwnProperty("device") && attributes.device.hasOwnProperty("material") ) ? attributes.device.material : "" }` );
		let hopper = getHopperByConfiguration( indexRecycler , dataBlock );
		
		returned[`h${indexRecycler}Currency`] = dataBlock.substring( 0 , 3 );
		returned[`h${indexRecycler}Value`] = ( hopper && hopper?.hasOwnProperty("value") ) ? hopper.value : -1;
		returned[`h${indexRecycler}SelectorRef`] = ( hopper && hopper?.hasOwnProperty("realSize") ) ? hopper.realSize : "";
		returned[`h${indexRecycler}SortHole`] = ( hopper && hopper?.hasOwnProperty("sortHole") ) ? hopper.sortHole : -1;
		returned[`h${indexRecycler}LifetimeDispensed`] = ( fullData && fullData?.hasOwnProperty("HopperLifetimeDispense") && fullData.HopperLifetimeDispense[parseInt(indexRecycler , 10) -1] ) ? fullData.HopperLifetimeDispense[parseInt(indexRecycler , 10) -1] : 0;
		returned[`h${indexRecycler}SuccessDispensed`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("HopperError") && fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("CoinsDispensed") ) ? fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1].CoinsDispensed : 0;
		returned[`h${indexRecycler}ErrorDispensed`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("HopperError") && fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("Errors") ) ? fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1].Errors : 0;
		returned[`h${indexRecycler}CoinEjectTimeoutExceeded`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("HopperError") && fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("CoinEjectTimeoutExceeded") ) ? fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1].CoinEjectTimeoutExceeded : 0;
		returned[`h${indexRecycler}Accepted`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("AcceptedCoinType") && fullData.Statistic.AcceptedCoinType[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("Total") ) ? fullData.Statistic.AcceptedCoinType[parseInt(indexRecycler , 10) -1].Total : 0;
		returned[`h${indexRecycler}Sorted`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("SortedCoinType") && fullData.Statistic.SortedCoinType[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("Total") ) ? fullData.Statistic.SortedCoinType[parseInt(indexRecycler , 10) -1].Total : 0;
		returned[`h${indexRecycler}Rejected`] = {
		};
		returned[`h${indexRecycler}Rejected`][`h${indexRecycler}DeviceDisabled`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("AcceptorRejectedCoinType") && fullData.Statistic.AcceptorRejectedCoinType[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("DeviceDisabled") ) ? fullData.Statistic.AcceptorRejectedCoinType[parseInt(indexRecycler , 10) -1].DeviceDisabled : 0;
		returned[`h${indexRecycler}Rejected`][`h${indexRecycler}SerialInhibit`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("AcceptorRejectedCoinType") && fullData.Statistic.AcceptorRejectedCoinType[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("SerialInhibit") ) ? fullData.Statistic.AcceptorRejectedCoinType[parseInt(indexRecycler , 10) -1].SerialInhibit : 0;
		returned[`h${indexRecycler}MisroutedAfterSortCoinType`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("MisroutedAfterSortCoinType") && fullData.Statistic.MisroutedAfterSortCoinType[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("Total") ) ? fullData.Statistic.MisroutedAfterSortCoinType[parseInt(indexRecycler , 10) -1].Total : 0;
		returned[`h${indexRecycler}MisroutedBeforeSortCoinType`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("MisroutedBeforeSortCoinType") && fullData.Statistic.MisroutedBeforeSortCoinType[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("Total") ) ? fullData.Statistic.MisroutedBeforeSortCoinType[parseInt(indexRecycler , 10) -1].Total : 0;
		returned[`h${indexRecycler}Overcurrent`] = ( fullData && fullData?.hasOwnProperty("Statistic") && fullData.Statistic?.hasOwnProperty("HopperError") && fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1]?.hasOwnProperty("Overcurrent") ) ? fullData.Statistic.HopperError[parseInt(indexRecycler , 10) -1].Overcurrent : 0;
		return returned;
	}
	
	const extractSensorValue = ( sensorList , id ) => {
		let value = -1;
		sensorList.map( ( candidate ) => {
			try {
				if( candidate.ElementId === id ) {
					value = candidate.SensorSignalEstimation;
				}
			} catch ( error ) {
				//secure unsafe data silently
			}
			
			return true;
		} );
		return value;
	}
	
	const renderLedColorForState = ( state ) => {
		if( state === null || state === undefined || state === "" || state === "error" ) {
			return "red";
		}
		return "green";
	}
	
	const renderAnomalyLayout = ( part , data , isSmall = false , isBack = false ) => {
		
		let url = `url("/Resources/${model}/${subDir}/model-${model}${(isBack) ? "-BACK" : ""}-${part}-anomaly.png")`;
		if( !subDir ) {
			 url = `url("/Resources/${model}/model-${model}${(isBack) ? "-BACK" : ""}-${part}-anomaly.png")`;
		}
		
		if ( part === "r3-4" ) {
			url = `url("/Resources/${model}/${subDir}/model-${model}${(isBack) ? "-BACK" : ""}-recycler_3_4-anomaly.png")`;
		} else if ( part === "r5-6" ) {
			url = `url("/Resources/${model}/${subDir}/model-${model}${(isBack) ? "-BACK" : ""}-recycler_5_6-anomaly.png")`;
		} else if ( part === "r1-2" ) {
			url = `url("/Resources/${model}/${subDir}/model-${model}${(isBack) ? "-BACK" : ""}-recycler_1_2-anomaly.png")`;
		}

		let style = {
			backgroundImage:url, 
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`
		};
		
		if( isSmall ) {
			style.backgroundSize = `contain`;
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		
		if( data && data[part] && data[part].hasOwnProperty("state") && data[part].state === "error" ) {
			return (<div className="component" style={style} ></div>);
		} else {
			if( variant && ( variant.startsWith("BNR3") || variant.includes("BNR3") ) ) {
				if ( part === "r1-2" ) {
					if( data && data.hasOwnProperty( "recycler1" ) && data.hasOwnProperty( "recycler2" ) ) {
						if( data.recycler1.state === "error" || data.recycler2.state === "error" ) {
							return (<div className="component" style={style} ></div>);
						}
					}
				} else if ( part === "r3-4" ) {
					if( data && data.hasOwnProperty( "recycler3" ) && data.hasOwnProperty( "recycler4" ) ) {
						if( data.recycler3.state === "error" || data.recycler4.state === "error" ) {
							return (<div className="component" style={style} ></div>);
						}
					}
				}
			} else if( variant && variant.includes("BNR4") ) {
				if ( part === "r3-4" ) {
					if( data && data.hasOwnProperty( "recycler1" ) && data.hasOwnProperty( "recycler2" ) ) {
						if( data.recycler1.state === "error" || data.recycler2.state === "error" ) {
							return (<div className="component" style={style} ></div>);
						}
					}
				} else if ( part === "r5-6" ) {
					if( data && data.hasOwnProperty( "recycler3" ) && data.hasOwnProperty( "recycler4" ) ) {
						if( data.recycler3.state === "error" || data.recycler4.state === "error" ) {
							return (<div className="component" style={style} ></div>);
						}
					}
				}
			}
		}
		return null;
	}
	
	const renderSelectionLayout = ( part , isSmall = false , isBack = false ) => {
		let url = `url("/Resources/${model}/${subDir}/model-${model}${(isBack) ? "-BACK" : ""}-${part}-selected.png")`;
		if( !subDir ) {
			url = `url("/Resources/${model}/model-${model}${(isBack) ? "-BACK" : ""}-${part}-selected.png")`;
		}
		let style = {
			backgroundImage:url, 
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`,
			display:"none"
		};
		
		if( isSmall ) {
			style.backgroundSize = `contain`;
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		return (<div id={`${part}${(isBack) ? "-BACK" : ""}-selected-layout`} className="component" style={style} ></div>);
		//return (<div id={`${part}-selected-layout`} className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}${(isBack) ? "-BACK" : ""}-${part}-selected.png")`, width:`${originalWidth}px`, height:`${originalHeight}px` , display:"none"}} ></div>);
	}
	
	const originalWidth = 500;
	const originalHeight = 700;
	const smallRatio = 2.5;
	const rootId = "device-schema";
	const rootSmallId = "device-small-schema";
	
	let css = "DeviceSchema-root";
	let cssSmall = "DeviceSchema-root-small";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	
	const scaleHeight = ( width , ratio ) => {
		return width * ratio;
	}
	
	const getFrontBnr4 = ( isSmall = false ) => {
		let style = {
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`
		};
		
		if( isSmall ) {
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		
		return (
			<div id={`${(isSmall) ? rootSmallId : rootId}`} className={`${(isSmall) ? cssSmall : css} front`} style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-background.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} onClick={handleClick} onMouseMove={handleMouseMove}>
				{renderAnomalyLayout( "mainModule" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "cover" , deviceData.mainModule , isSmall , false )}
				{renderAnomalyLayout( "owl" , deviceData.mainModule , isSmall , false )}
				{renderAnomalyLayout( "cashbox" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "loader" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "r3-4" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "r5-6" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "bundler" , deviceData , isSmall , false )}

				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-usb-default.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-power-default.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-acceptor-default.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				
				{renderAnomalyLayout( "usb" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "power" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "acceptor" , deviceData.mainModule , isSmall , false )}
				
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-accessories-alt.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-3-led-${renderLedColorForState(deviceData.recycler1.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-4-led-${renderLedColorForState(deviceData.recycler2.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-5-led-${renderLedColorForState(deviceData.recycler3.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-6-led-${renderLedColorForState(deviceData.recycler4.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>

				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-accessories.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-acceptor-input-${renderLedColorForState(deviceData.mainModule.acceptor.input.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-acceptor-output-${renderLedColorForState(deviceData.mainModule.acceptor.output.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
					{/*<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-cashbox-led-red.png")`, width:`${originalWidth}px`, height:`${originalHeight}px`}} ></div>*/}
				
				{renderSelectionLayout("bundler" , isSmall , false)}
				{renderSelectionLayout("mainModule", isSmall , false)}
				{renderSelectionLayout("r5-6", isSmall , false)}
				{renderSelectionLayout("r3-4", isSmall , false)}
				{renderSelectionLayout("loader", isSmall , false)}
				{renderSelectionLayout("cashbox", isSmall , false)}
				{renderSelectionLayout("acceptor", isSmall , false)}
				{renderSelectionLayout("power", isSmall , false)}
				{renderSelectionLayout("usb", isSmall , false)}
			</div>	
		);
	} 
	
	const getFrontBnr3 = ( isSmall = false ) => {
		let style = {
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`
		};
		
		if( isSmall ) {
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		
		return (
			<div id={`${(isSmall) ? rootSmallId : rootId}`} className={`${(isSmall) ? cssSmall : css} front`} style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-background.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} onClick={handleClick} onMouseMove={handleMouseMove}>
				{renderAnomalyLayout( "mainModule" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "cover" , deviceData.mainModule , isSmall , false )}
				{renderAnomalyLayout( "owl" , deviceData.mainModule , isSmall , false )}
				{renderAnomalyLayout( "cashbox" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "r1-2" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "r3-4" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "bundler" , deviceData , isSmall , false )}

				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-usb-default.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-power-default.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-acceptor-default.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				
				{renderAnomalyLayout( "usb" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "power" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "acceptor" , deviceData.mainModule , isSmall , false )}
				
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-accessories-alt.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-1-led-${renderLedColorForState(deviceData.recycler1.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-2-led-${renderLedColorForState(deviceData.recycler2.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-3-led-${renderLedColorForState(deviceData.recycler3.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-recycler-4-led-${renderLedColorForState(deviceData.recycler4.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>

				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-accessories.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-acceptor-input-${renderLedColorForState(deviceData.mainModule.acceptor.input.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-acceptor-output-${renderLedColorForState(deviceData.mainModule.acceptor.output.state)}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
					{/*<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-cashbox-led-red.png")`, width:`${originalWidth}px`, height:`${originalHeight}px`}} ></div>*/}
				
				{renderSelectionLayout("bundler" , isSmall , false)}
				{renderSelectionLayout("mainModule", isSmall , false)}
				{renderSelectionLayout("r3-4", isSmall , false)}
				{renderSelectionLayout("r1-2", isSmall , false)}
				{renderSelectionLayout("cashbox", isSmall , false)}
				{renderSelectionLayout("acceptor", isSmall , false)}
				{renderSelectionLayout("power", isSmall , false)}
				{renderSelectionLayout("usb", isSmall , false)}
			</div>	
		);
	} 
	
	const getFrontScr = ( isSmall = false ) => {
		let style = {
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`
		};
		
		if( isSmall ) {
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		
		return (
			<div id={`${(isSmall) ? rootSmallId : rootId}`} className={`${(isSmall) ? cssSmall : css} front`} style={{backgroundImage:`url("/Resources/${model}/model-${model}-background.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} onClick={handleClick} onMouseMove={handleMouseMove}>
				{renderAnomalyLayout( "acceptor" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "vault" , deviceData.mainModule , isSmall , false )}
				{renderAnomalyLayout( "recyclers" , deviceData.mainModule , isSmall , false )}
				{renderAnomalyLayout( "cashbox" , deviceData , isSmall , false )}
				{renderAnomalyLayout( "chassis" , deviceData , isSmall , false )}
				
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-accessories.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
					
				{renderSelectionLayout("acceptor" , isSmall , false)}
				{renderSelectionLayout("vault", isSmall , false)}
				{renderSelectionLayout("recyclers", isSmall , false)}
				{renderSelectionLayout("cashbox", isSmall , false)}
				{renderSelectionLayout("chassis", isSmall , false)}
			</div>	
		);
	} 
	
	const getFrontCls = ( isSmall = false ) => {
		let style = {
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`
		};
		
		if( isSmall ) {
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		/*<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-output-tray.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-side.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-accessories.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>*/
		return (
			<div id={`${(isSmall) ? rootSmallId : rootId}`} className={`${(isSmall) ? cssSmall : css} front`} style={{backgroundImage:`url("/Resources/${model}/model-${model}-background.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} onClick={handleClick} onMouseMove={handleMouseMove}>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
			</div>	
		);
	}
	
	const getBackBnr = ( isSmall = false ) => {
		let style = {
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`
		};
		
		if( isSmall ) {
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		
		return (
			<div id={`${(isSmall) ? rootSmallId : rootId}`} className={`${(isSmall) ? cssSmall : css} back`} style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-BACK-background.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} onClick={handleClick} onMouseMove={handleMouseMove}>
				{renderAnomalyLayout( "cover" , deviceData.mainModule , isSmall , true )}
				{renderAnomalyLayout( "owl" , deviceData.mainModule , isSmall , true )}
				{renderAnomalyLayout( "spine" , deviceData , isSmall , true )}
				{renderAnomalyLayout( "mainModule" , deviceData , isSmall , true )}
				
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-BACK-acceptor.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-BACK-accessories.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/${subDir}/model-${model}-BACK-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				
				{renderSelectionLayout("mainModule" , isSmall , true)}
				{renderSelectionLayout("spine" , isSmall , true)}
			</div>	
		);
	} 
	
	const getBackCls = ( isSmall = false ) => {
		let style = {
			width:`${originalWidth}px`, 
			height:`${originalHeight}px`
		};
		
		if( isSmall ) {
			style.width = `${parseInt(originalWidth/smallRatio , 10)}px`;
			style.height = `${parseInt(scaleHeight(originalWidth/smallRatio , originalHeight/originalWidth )  , 10)}px`;
		}
		
		const references = extractReferencesFromData();
	
		let numbers = [];
		references.map( (candidate) => {
			if( candidate !== null && candidate !== undefined ) {
				numbers.push( <div key={`number-${candidate}`} className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-BACK-number-${candidate}.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div> );
			}
			return true;
		} );
		
		let separators = [];
		references.map( (candidate) => {
			if( candidate !== null && candidate !== undefined ) {
				if( !candidate.includes("-") ) {
					separators.push( <div key={`separator-${candidate}`} className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-BACK-sep${candidate}-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div> );
				} else {
					if( (candidate.match(/-/g) || []).length === 1 && ( candidate !== "2-1" && candidate !== "3-2" && candidate !== "2-3" && candidate !== "6-7" && candidate !== "7-6" ) ) {
						let extracted = candidate.substring(candidate.lastIndexOf("-") + 1);
						if( extracted !== "4" && extracted !== "5" && candidate !== "7-8" ) {
							separators.push( <div key={`separator-${candidate}`} className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-BACK-sep${extracted}-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div> );
						} else if ( candidate !== "7-8" ) {
							separators.push( <div key={`separator-7`} className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-BACK-sep7-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div> );
						}
					}
				}
			}
			return true;
		} );
		
		let selector = [];
		references.map( (candidate) => {
			if( candidate !== null && candidate !== undefined ) {
				selector.push( renderSelectionLayout( `hopper${candidate}` , isSmall , true) );
			}
			return true;
		} );
		
		return (
			<div id={`${(isSmall) ? rootSmallId : rootId}`} className={`${(isSmall) ? cssSmall : css} back`} style={{backgroundImage:`url("/Resources/${model}/model-${model}-BACK-background.png")`, width:style.width, height:style.height , backgroundSize:`contain` , cursor:`pointer`}} onClick={handleClick} onMouseMove={handleMouseMove}>
				{selector.map( item => item )}
				<div className="component" style={{backgroundImage:`url("/Resources/${model}/model-${model}-BACK-strokes.png")`, width:style.width, height:style.height , backgroundSize:`contain`}} ></div>
				{separators.map( item => item )}
				{numbers.map( item => item )}
			</div>	
		);
	} 
	
	const getSchema = position => {
		if( position === "front" ) {
			if( schemaPosition[position] === Views.FRONT ) {
				if( variant && variant.includes("BNR3") ) {
					return getFrontBnr3( false );
				} else if( variant && variant.includes("BNR4") ) {
					return getFrontBnr4( false );
				} else if ( model === "CLS" ) {
					return getFrontCls( false );
				} else if ( model === "SCR" ) {
					return getFrontScr( false );
				} else if ( model === "BNR" ) {
					return getFrontBnr3( false );
				}
				return null;
			} else {
				if ( model === "CLS" ) {
					return getBackCls( false );
				} else if ( model === "SCR" ) {
					//To do
					return null;
				}
				return getBackBnr( false );
			}
		} else {
			if( schemaPosition[position] === Views.FRONT ) {
				if( variant && variant.includes("BNR3") ) {
					return getFrontBnr3( true );
				} else if( variant && variant.includes("BNR4") ) {
					return getFrontBnr4( true );
				} else if ( model === "CLS" ) {
					return getFrontCls( true );
				} else if ( model === "SCR" ) {
					return getFrontScr( true );
				} else if ( model === "BNR" ) {
					return getFrontBnr3( true );
				}
				return null;
			} else {
				if ( model === "CLS" ) {
					return getBackCls( true );
				} else if ( model === "SCR" ) {
					//To do
					return null;
				}
				return getBackBnr( true );
			}
		}
	}

	const getFirstActiveDeviceForBox = devices => {
		let found = null;
		if( devices ) {
			devices.forEach( device => {
				if( !found && analyzer.isActiveAtRequestTime( device.periods , new Date().toISOString() ) ) {
					if( ! device.name.includes("_MDB_CoinRecycler_") &&
						! device.name.includes("_MDB-CoinRecycler_") &&
						! device.name.includes("_VirtualDevice_") ) {
						found = device;
					}
				}
			} );
		}
		return found;
	}

	const getDeviceById = devices => {
		let found = null;
		if( devices ) {
			devices.forEach( device => {
				if( device.id === props.selectedDevice ) {
					if( ! device.name.includes("_MDB_CoinRecycler_") &&
						! device.name.includes("_MDB-CoinRecycler_") &&
						! device.name.includes("_VirtualDevice_") ) {
						found = device;
					}
				}
			} );
		}
		return found;
	}
	
	const getSubDirectoryForVariant = () => {
		if( variant && variant.includes("BNR3") ) {
			return "BNR3";
		} else if( variant && variant.includes("BNR4") ) {
			return "BNR4";
		} else if ( model === 'BNR' ) {
			return "BNR3";
		}
		return "";
	}
	
	const filterDevicesForSelection = devices => {
		const filtered = [];
		if( devices ) {
			devices.forEach( candidate => {
				if( candidate.informations &&
					candidate.informations.identity &&
					["BNR" , "CLS" , "SCR"].includes( candidate.informations.identity.model ) ) {
					filtered.push( candidate );
				}
				return true;
			} );
		}
		return filtered;
	}
	
	const renderSwapSchema = model => {
		if( model && model === "SCR" ) {
			return null;
		}
		return ( <FontAwesomeIcon icon={['fas', 'exchange-alt']} className="display-4 swap-icon" onClick={(evt) => {toggleSchemas()}}/> ) ;
	}

	const findIndexForPayStation = id => {
		let index = 1;
		candidateBoxes.forEach( candidate => {
			if( candidate.id === id ) {
				index = candidateBoxes.indexOf( candidate );
			}
		} )
		return index;
	}

	let adjustmentSize = 0;
	if( props.isDesktop ) {
		adjustmentSize = 2;
	}
	const analyzer = new ConfigAnalyzer( props.payStations );
	const finder = new PayStationFinder( props.payStations );
	props?.payStations?.forEach( payStation => {
		const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
		payStation.box.extraData = finder.extractCompanyShopLabel( finder.buildTerminalExtraData( terminal ) );
	} );

	const candidateBoxes = [];
	if( props.boxList ) {
		props.boxList.forEach( candidate => {
			const payStation = getPayStationForBoxId( candidate.id );
			if( hasDeviceWithTechnicalData( payStation.devices ) ) {
				if( ! candidateBoxes.includes( candidate ) ) {
					candidateBoxes.push( candidate );
				}
			}
		} );
	}
	
	if( selectedBox === null && candidateBoxes.length > 0 ) {
		const initId = ( props.selected ) ? findIndexForPayStation( props.selected ) : 0;
		//init with 1st index
		setSelectedBox( candidateBoxes[initId] );
		const payStation = getPayStationForBoxId( candidateBoxes[initId].id );
		if( props.selectedDevice > 0 ) {
			setSelectedDevice( getDeviceById( payStation.devices ) );
		} else {
			setSelectedDevice( getFirstActiveDeviceForBox( payStation.devices ) );
		}
	}
	
	const deviceTechnicalData = extractTechnicalData( selectedDevice );
	
	const model = (selectedDevice) ? selectedDevice.informations.identity.model : "";
	let variant = (selectedDevice) ? selectedDevice.informations.identity.name : "";
	if( model === 'BNR' && variant === '' ) {
		variant = 'BNR3';
	}
	const subDir = getSubDirectoryForVariant();
	
	let attributes = {};
	try {
		attributes = ( deviceTechnicalData.attributes && deviceTechnicalData.attributes !== `""` && deviceTechnicalData.attributes !== ``) ? JSON.parse( deviceTechnicalData.attributes ) : {};
	} catch ( error ) {
		console.error( error );
	}

	const data = buildDataStructure( selectedDevice , new DeviceTemplate( model , variant , attributes ).getTemplate() , attributes );//no data for template ! //sampleData;
	const deviceAnalyzer = new DeviceDataAnalyzer( model , variant , attributes , data , Mode.ALL );
	deviceAnalyzer.setLocale( props.locale );
	const deviceData = deviceAnalyzer.getDeviceState();
	const registeredShapes = deviceAnalyzer.getRegisteredShapes( schemaPosition.front , extractReferencesFromData() );
	
	if( candidateBoxes.length === 0 ) {
		return null;
	}

	const payStation = ( selectedBox ) ? getPayStationForBoxId( selectedBox.id ) : null;
	
	return (
		<React.Fragment>
			<Grid container spacing={1} className="grid-root">
				<Grid item xs={12} md={6} lg={6} className={`grid-item`}>
					<BoxSelector boxes={candidateBoxes} 
								 allowEmpty={true}
								 label={I18n.get("Select box")}
								 selected={candidateBoxes.indexOf( selectedBox )} 
								 defaultValue={`${candidateBoxes[0].id}`}
								 showIcon={true}
								 isDarkStyle={props.isDarkStyle}
								 onChange={handleChange} />
				</Grid>
				<Grid item xs={12} md={6} lg={6} className={`grid-item`}>
					<DeviceSelector  devices={( selectedBox ) ? filterDevicesForSelection( payStation.devices ) : []}
									 allowEmpty={false}
									 label={I18n.get("Select device")}
									 activeOnly={true}
									 selected={( selectedDevice ) ? payStation.devices.indexOf( selectedDevice ) : 0}
									 defaultValue={`${( selectedDevice && selectedDevice.id ) ? selectedDevice.id : ""}`}
									 showIcon={true}
									 isDarkStyle={props.isDarkStyle}
									 onChange={handleChangeDevice} />
				</Grid>
				<Grid item xs={12} md={6} lg={6 - adjustmentSize} className={`grid-item Schema-container`}>
						{getSchema( "front" )}
						{getSchema( "back" )}
						{renderSwapSchema(model)}
				</Grid>
				
				<Grid item xs={12} md={6} lg={6 + adjustmentSize} className={`grid-item`}>
					<DeviceTechnicalData isDarkStyle={props.isDarkStyle}
										 model={model}
										 variant={variant}
										 attributes={attributes}
										 data={data}
										 locale={props.locale}
										 onInternalNavigation={handleInternalNavigation}
										 onSchemaLocationRequested={handleLocateOnSchema}
										 ref={(component) => dataDisplayer = component}/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
