import React from 'react';
import { I18n } from 'aws-amplify/utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	TextField,
	List,
	InputAdornment,
	ListItem,
	Tooltip ,
	IconButton
} from "@mui/material";
import { roles , getIconForNode } from '../../../Models/Roles.js';

import './InstallationManager.css';
import {Search} from "@mui/icons-material";
import NoDataPlaceholder from "../NoDataPlaceholder/NoDataPlaceholder";
import {closePeriods} from "../../../graphql/mutations";
import GraphQlTool from "../../../Utils/GraphQlTool";
import {getAccounts} from "../../../graphql/queries";
import PayStationFinder from "../../../Utils/PayStationFinder";
import DialogRemoteCommands from "./DialogRemoteCommands";
import DialogCustomNameManagement from "./DialogCustomNameManagement";
import UUID from "../../../Utils/UUID";
import DialogSendEmailToAccountOwner from "./DialogSendEmailToAccountOwner";

const factoryUUID = new UUID();

export default function InstallationManager( props ) {
	const [filterName, setFilterName] = React.useState( null );
	const [filterSoftware, setFilterSoftware] = React.useState( null );
	const [filterOutdated, setFilterOutdated] = React.useState( null );
	const [selected, setSelected] = React.useState( null );
	const [accounts, setAccounts] = React.useState( null );
	const [opened, setOpened] = React.useState( false );
	const [displayed, setDisplayed] = React.useState( false );
	const [selectedAccount, setSelectedAccount] = React.useState( null );
	const [boxPreferences, setBoxPreferences] = React.useState( null );

	const DESTINATION = {
		DIAGNOSTIC: 0,
		SYSTEM_DETAILS: 1
	}

	const RESELLER = 4;
	const CUSTOMER = 5;

	const loadAccounts = () => {
		if( props.API ) {
			props.API
				.graphql({ query: getAccounts, variables: { accountId:props.user.accountId }})
				.then( returned => {
					const tool = new GraphQlTool( 'getAccounts' , returned );
					setAccounts( tool.extract() );

				})
				.catch((error) => {
					console.error("error" , error);
				})
		}
	}

	const extractReseller = box => {
		if( box && box.informations && box.informations.sender && box.informations.sender["reseller_id"] ) {
			return { code: box.informations.sender["reseller_id"] };
		}
		return { code: "Error" };
	};

	const extractCustomer = box => {
		if( box && box.informations && box.informations.sender && box.informations.sender["customer_code"] ) {
			return { code: box.informations.sender["customer_code"] };
		}
		return { code: "Error" };
	};

	const extractBoxName = box => {
		if( box && box.informations && box.informations.attributes && box.informations.attributes.name ) {
			return box.informations.attributes.name;
		}

		if( box && box.informations && box.informations.identity &&
			box.informations.identity.model && box.informations.identity.serial ) {
			return `${box.informations.identity.model} ${box.informations.identity.serial}`;
		}

		return I18n.get( "Unknown" );
	};

	const extractSoftwareArchitecture = software => {
		if( software && software.attributes && software.attributes.type ) {
			return software.attributes.type;
		}

		return I18n.get( "Unknown" );
	};

	const extractSoftwareName = software => {
		if( software && software.identity && software.identity.model ) {
			return software.identity.model;
		}

		return I18n.get( "Unknown" );
	};

	const extractSoftwareVersion = software => {
		if( software && software.attributes && software.attributes.version ) {
			return software.attributes.version;
		}

		return I18n.get( "Unknown" );
	};

	const onFilterNameChanges = evt => {
		setFilterName( evt.target.value );
	};

	const onFilterSoftwareChanges = evt => {
		setFilterSoftware( evt.target.value );
	};

	const onFilterOutdatedChanges = ( evt , newValue ) => {
		setFilterOutdated( newValue );
	};

	const handleClickClosePeriod = () => {
		if( ! selected ) return;

		if( props.API ) {
			props.API.graphql({
				query: closePeriods,
				variables: {
					boxId: selected
				}
			})
			.then( returned => {
				const tool = new GraphQlTool( 'closePeriods' , returned );
				const result = tool.extract();
				if( ! result.error ) {
					if( props.onPaystationRemoved ) {
						props.onPaystationRemoved( selected );
					}
					setSelected( null );
				}
			})
			.catch((error) => {
				console.log("error" , error);
			})
		}
	};

	const handleClickRemoteCommands = () => {
		if( ! selected ) return;

		setOpened( true );
	}

	const handleClearRemoteCommands = () => {
		setOpened( false );
	}
	
	const handleClickCustomNameManagement = () => {
		if( ! selected ) return;
		props.observer?.payStations?.forEach( payStation => {
			if( payStation?.box?.id === selected ) {
				const selectedPreferences = {
					boxId: selected,
					box: extractBoxName( payStation.box ),
					terminals: []
				};

				payStation.terminals?.forEach( terminal => {
					if( payStation.terminals?.indexOf( terminal ) === 0 ) {
						selectedPreferences.company = ( terminal.informations?.attributes?.company ) ? terminal.informations?.attributes?.company : '';
						selectedPreferences.shop = ( terminal.informations?.attributes?.shop ) ? terminal.informations?.attributes?.shop : '';
					}

					selectedPreferences.terminals.push( {
						name: ( terminal.informations.attributes.terminal ) ? terminal.informations.attributes.terminal : terminal.name
					} );
				} );

				setBoxPreferences( selectedPreferences );
			}
		} );
	}

	const handleClearCustomNameManagement = () => {
		setBoxPreferences( null );
	}

	const handleClickSendEmailToAccountOwner = () => {
		if( ! selected ) return;
		props.observer?.payStations?.forEach( payStation => {
			if( payStation?.box?.id === selected ) {
				console.log( 'Do something from here' , payStation );
				const reseller = extractReseller( payStation.box );
				const resellerAccount = accounts.filter( item => item.code === reseller.code && item.type === RESELLER );
				if( resellerAccount.length === 1 ) {
					console.log( 'resellerAccount' , resellerAccount[0] );
					resellerAccount[0].payStation = payStation;
					setSelectedAccount( resellerAccount[0] );
					setDisplayed( true );
				}
			}
		} );
	}

	const handleClearSendEmailToAccountOwner = () => {
		setDisplayed( false );
		delete selectedAccount.payStation;
		setSelectedAccount( null );
	}

	const filterByName = list => {
		if( ! filterName ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( payStation => {
				const reseller = extractReseller( payStation.box );
				const customer = extractCustomer( payStation.box );
				const resellerAccount = accounts.filter( item => item.code === reseller.code && item.type === RESELLER );
				const customerAccount = accounts.filter( item => item.code === customer.code && item.type === CUSTOMER );

				try {
					if( payStation.box.informations.attributes.name.toLowerCase().includes( filterName.toLowerCase() ) ||
						( resellerAccount.length > 0 && resellerAccount[0].name.toLowerCase().includes( filterName.toLowerCase() ) ) ||
						( customerAccount.length > 0 && customerAccount[0].name.toLowerCase().includes( filterName.toLowerCase() ) ) ||
						( payStation.devices.some( device => device?.informations?.identity?.serial?.toLowerCase().includes( filterName.toLowerCase() ) ) ) ) {
						filtered.push( payStation );
					}
				} catch ( error ) {
					console.error( error );
				}
			} );
		}
		return filtered;
	}

	const filterBySoftware = list => {
		if( ! filterSoftware ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( payStation => {
				const name = extractSoftwareName( payStation.box.software );
				const version = extractSoftwareVersion( payStation.box.software );
				const type = extractSoftwareArchitecture( payStation.box.software );
				if( name.toLowerCase().includes( filterSoftware.toLowerCase() ) ||
					version.toLowerCase().includes( filterSoftware.toLowerCase() ) ||
					type.toLowerCase().includes( filterSoftware.toLowerCase() ) ) {
					filtered.push( payStation );
				}
			} );
		}
		return filtered;
	}

	const filterByOutdatedVersion = list => {
		if( ! filterOutdated ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( payStation => {
				//to implements
			} );
		}
		return filtered;
	}

	const navigateTo = ( destination , payStation ) => {
		if( destination === DESTINATION.DIAGNOSTIC )  {
			if( props.onRequestedBoxDiagnostic ) {
				props.onRequestedBoxDiagnostic( payStation.box.id );
			}
		} else if ( destination === DESTINATION.SYSTEM_DETAILS ) {
			if( props.onRequestedBoxOverview ) {
				props.onRequestedBoxOverview( payStation.box.id );
			}
		}
	}

	const renderRemoteCommand = () => {
		/*if( ! props.user.accountLabel.startsWith( 'Cashmag' ) ) {
			return null;
		}*/

		return (
			<div className={`button ${( ! selected )?'disabled':''}`}  onClick={ handleClickRemoteCommands }>
				{ I18n.get("Remote commands") }
			</div>
		);
	}

	const renderCustomNameManagement = () => {
		if( ! props.user.accountLabel.startsWith( 'Cashmag' ) ) {
			return null;
		}

		return (
			<div className={`button ${( ! selected )?'disabled':''}`}  onClick={ handleClickCustomNameManagement }>
				{ I18n.get("Customization") }
			</div>
		);
	}

	const renderResellerEmail = () => {
		if( ! props.user.accountLabel.startsWith( 'Cashmag' ) ) {
			return null;
		}

		return (
			<div className={`button ${( ! selected )?'disabled':''}`}  onClick={ handleClickSendEmailToAccountOwner }>
				{ I18n.get("Message") }
			</div>
		);
	}

	const renderHeader = () => {
		return (
			<div className={`header-admin`}>
				<div className={`action-bar`}>
					<div className={`title`}>{ I18n.get("Installations") }</div>
					<div className={`count`}>({ ( props.payStations ) ? props.payStations.length : 0 })</div>
					<div className={`spacer`}></div>
					<div className={`actions`}>
						{renderResellerEmail()}
						{renderCustomNameManagement()}
						{renderRemoteCommand()}
						<div className={`button ${( ! selected )?'disabled':''}`}  onClick={ handleClickClosePeriod }>
							{ I18n.get("Close period") }
						</div>
						{/*<div className={`button ${( ! selected )?'disabled':''}`}  onClick={handleClickNotifyGateway}>
							{ I18n.get("Notifier") }
						</div>*/}
					</div>
				</div>
				<div className={`filter-bar`}>
					<TextField id="search-installations"
							   className={`search-field-expand`}
							   label={ I18n.get("FILTER_INSTALLATION") }
							   variant="outlined"
							   size="small"
							   InputProps={{
								   startAdornment: (
									   <InputAdornment position="start">
										   <Search />
									   </InputAdornment>
								   ),
							   }}
							   onChange={ onFilterNameChanges }/>
					<div className={`spacer`}></div>
					<TextField id="search-software"
							   className={`search-field-medium`}
							   label={ I18n.get("Filter installation by software") }
							   variant="outlined"
							   size="small"
							   InputProps={{
								   startAdornment: (
									   <InputAdornment position="start">
										   <Search />
									   </InputAdornment>
								   ),
							   }}
							   onChange={ onFilterSoftwareChanges }/>
					<div className={`spacer`}></div>
					{/*<ToggleButtonGroup
						value={filterOutdated}
						exclusive
						onChange={onFilterOutdatedChanges}
						aria-label={ I18n.get("Filter outdated software versions") }
					>
						<Tooltip title={ I18n.get("Filter outdated software versions") }>
							<ToggleButton value="obsolete-version" aria-label={ I18n.get("Filter outdated software versions") }>
								<SecurityUpdateWarningIcon />
							</ToggleButton>
						</Tooltip>
					</ToggleButtonGroup>*/}
				</div>
			</div>
		);
	};

	const renderListHeader = () => {
	    return (
	        <div className={`list-header payStation-card`}>
	            <div className={`general-block`}>
	                <div className={`icon-spacer`}></div>
	                <div className={`box-name`}>{ I18n.get('Hardware') }</div>
	                <div className={`spacer`}></div>
	                <div className={`software-block`}>{ I18n.get('software') }</div>
	                <div className={`device-block`}>{ I18n.get('mainModule') }</div>
	                <div className={`terminal-block`}></div>
	                <div className={`navigation-block`}>{ I18n.get('Tools') }</div>
                </div>
                <div className={`identification`}>{ I18n.get('Reseller/Client') }</div>
	        </div>
	    );
	};

	const renderListItem = payStation => {
		const state = "collapsed";
		return (
			<ListItem key={payStation.box.id}
					  id={`installation-${payStation.box.id}`}
					  className={`payStation-card ${(selected === payStation.box.id)?'selected':''}`}
					  onClick={() => {
						  const selection = window.getSelection();
						  if (selection.toString().length > 0) {
							  return;
						  }

						  setSelected( payStation.box.id );
						  const expandable = document.querySelector( `.item-expandable .expandable.box-${payStation.box.id}` );
						  if( expandable ) {
							  if( expandable.classList.contains( state ) ) {
								  expandable.classList.remove( state );
							  } else {
								  expandable.classList.add( state );
							  }
						  }
					  }}>
				<div className={`item-expandable`}>
					<div className={`main`}>
						{renderGeneral( payStation )}
						<div className={`identification`}>
							{renderReseller( payStation )}
							{renderCustomer( payStation )}
						</div>
					</div>
					<div className={`expandable ${state} box-${payStation.box.id}`}>{renderDetails( payStation )}</div>
				</div>
			</ListItem>
		);
	};

	const renderDetails = payStation => {
		const devices = [];
		payStation?.devices?.forEach( device => {
			if( payStation.box?.informations?.attributes?.devices?.includes( `5_${device.name}` ) ) {
				devices.push(
					<div key={factoryUUID.generate()} className={`device-line`}>
						<div className={`device-name`}>
							<FontAwesomeIcon icon={getIconForNode( {role: roles.DEVICE , name: device.informations?.identity?.model} )} />
							<div className={`device-spacer`}> </div>
							{(device.informations?.identity?.model) ? device.informations?.identity?.model.replace("MDB-CoinRecycler" , "Gryphon") : I18n.get("Unknown")}
						</div>
						<div className={`terminal-spacer`}> </div>
						<div className={`device-serial`}>{(device.informations?.identity?.serial) ? device.informations?.identity?.serial : I18n.get('Unknown')}</div>
					</div>
				);
			}
		} );

		let header = '';
		if( payStation?.terminals?.length > 0 ) {
			header = (
				<div className={`terminal-line`}>
					<div className={`terminal-name`}>{I18n.get('Terminal')}</div>
					<div className={`terminal-spacer`}> </div>
					<div className={`terminal-license`}>{I18n.get('License')}</div>
				</div>
			);
		}
		return (
			<>
				<div key={factoryUUID.generate()} className={`spacer top`}></div>
				<div key={factoryUUID.generate()} className={`terminal-line`}>
					<div className={`terminal-name`}>{I18n.get('Device')}</div>
					<div className={`terminal-spacer`}> </div>
					<div className={`terminal-license`}>{I18n.get('Serial')}</div>
				</div>
				{devices.map( item => item)}
				<div key={factoryUUID.generate()} className={`spacer section`}></div>
				{header}
				{payStation?.terminals?.map( item =>

 					<div key={factoryUUID.generate()} className={`terminal-line`}>
						<div className={`terminal-name`}>{(item.informations?.attributes?.terminal) ? item.informations?.attributes?.terminal : item.name}</div>
						<div className={`terminal-spacer`}> </div>
						<div className={`terminal-license`}>{(item.software?.attributes?.license)? item.software?.attributes?.license : I18n.get('Unknown')}</div>
					</div> )}
			</>
		);
	};

	const renderReseller = payStation => {
		if( ! props.user || ( props.user >= RESELLER ) || ! accounts ) {
			return null;
		}
		const reseller = extractReseller( payStation.box );
		const resellerAccount = accounts.filter( item => item.code === reseller.code && item.type === RESELLER );

		if( ! reseller || ! reseller.code || reseller.code.trim() === '' || ! resellerAccount || ! resellerAccount[0] ) {
			return null;
		}

		return (
			<div className={`reseller-block`}>
				<FontAwesomeIcon icon={getIconForNode( {role: roles.RESELLER} )} />
				<div className={`reseller-name`}>{resellerAccount[0]?.name}</div>
			</div>
		);
	};

	const renderCustomer = payStation => {
		if( ! props.user || ( props.user >= CUSTOMER ) || ! accounts ) {
			return null;
		}

		const customer = extractCustomer( payStation.box );
		const customerAccount = accounts.filter( item => item.code === customer.code && item.type === CUSTOMER );
		if( ! customer || ! customer.code || customer.code.trim() === '' || !customerAccount || !customerAccount[0] ) {
			return null;
		}

		return (
			<div className={`customer-block`}>
				<FontAwesomeIcon icon={getIconForNode( {role: roles.CUSTOMER} )} />
				<div className={`customer-name`}>{customerAccount[0]?.name}</div>
			</div>
		);
	};

	const renderGeneral = payStation => {
		const finder = new PayStationFinder( props.payStations );
		const boxName = extractBoxName( payStation.box );
		const extra = ( payStation.terminals.length > 0 ) ? finder.buildTerminalExtraData( payStation.terminals[0] ) : 'Non renseigné';
		return (
			<div className={`general-block`}>
				<FontAwesomeIcon icon={getIconForNode( {role: roles.BOX} )} />
				<div className={`box-name`}>
					<div className={`main`}>{boxName}</div>
					<div className={`extra`}>{extra}</div>
				</div>
				<div className={`spacer`}></div>
				{renderSoftware( payStation )}
				{renderDevices( payStation.devices , payStation.box?.informations?.attributes?.devices )}
				{renderTerminals( payStation.terminals )}
				{renderNavigationActions( payStation )}
			</div>
		);
	};

	const renderSoftware = payStation => {
		const name = extractSoftwareName( payStation.box.software );
		const version = extractSoftwareVersion( payStation.box.software );
		const type = extractSoftwareArchitecture( payStation.box.software );
		return (
			<div className={`software-block`}>
				<div className={`name`}>{name}</div>
				<div className={`properties`}>
					<div className={`version`}>{version}</div>
					<div className={`type`}>{type}</div>
				</div>
			</div>
		);
	};

	const renderDevices = ( devices , activesOnConfig ) => {
		const items = [];
		if( devices && devices.length > 0 ) {
			devices?.forEach( device => {
				if( activesOnConfig?.includes( `5_${device.name}` ) ) {
					items.push( renderOneDevice( device ) );
				}
			} );
		}

		return (
			<div className={`device-block`}>
				{items.map( item => item )}
			</div>
		);
	};

	const renderOneDevice = device => {
		const name = (
			device &&
			device.informations &&
			device.informations.identity
		) ? `${device.informations.identity.model}`  : I18n.get("Unknown");
		//`${device.informations.identity.model} ${device.informations.identity.serial}`
		return (
			<div key={device.id} className={`device-item`}>
				<FontAwesomeIcon icon={getIconForNode( {role: roles.DEVICE , name: name} )} />
				<div className={`type`}>{name.replace("MDB-CoinRecycler" , "Gryphon")}</div>
			</div>
		);
	};

	const renderTerminals = terminals => {
		return (
			<div className={`terminal-block`}>
				<FontAwesomeIcon icon={getIconForNode( {role: roles.TERMINAL} )} />
				<div className={`terminal-count`}> ( { terminals.length } ) </div>
			</div>
		);
	}

	const renderNavigationActions = payStation => {
		return (
			<div className={`navigation-block`}>
				{renderDiagnosticButton( payStation )}
				{renderSystemDetailsButton( payStation )}
			</div>
		);
	}

	const renderDiagnosticButton = payStation => {
		return (
            <Tooltip title={ I18n.get("Diagnostic") }>
                <IconButton aria-label="diagnostic"
                            onClick={() => { navigateTo( DESTINATION.DIAGNOSTIC , payStation ); }}>
                    <FontAwesomeIcon icon={["fas" , "stethoscope"]} />
                </IconButton>
            </Tooltip>
        );
	}

	const renderSystemDetailsButton = payStation => {
		return (
            <Tooltip title={ I18n.get("Overview") }>
                <IconButton aria-label="system details"
                            onClick={() => { navigateTo( DESTINATION.SYSTEM_DETAILS , payStation ); }}>
                    <FontAwesomeIcon icon={["fas" , "circle-info"]} />
                </IconButton>
            </Tooltip>
        );
	}

	const items = [];
	const filtered = filterByOutdatedVersion( filterBySoftware( filterByName( props.payStations ) ) );
	if( props.payStations && props.payStations.length > 0 ) {
		filtered.forEach( payStation => {
			items.push( renderListItem( payStation ) );
		} );
	}
	const shown = filtered.length < 1;
	if( shown && selected ) {
		setSelected( null );
	}

	if( ! accounts ) {
		loadAccounts();
	}
	const resellerAccounts = accounts?.filter( item => item.type === RESELLER );
	const customerAccounts = accounts?.filter( item => item.type === CUSTOMER );
	return (
		<React.Fragment>
			<div id="installation-manager">
				{renderHeader()}
				{renderListHeader()}
				<List>
					{items.map( item => item )}
				</List>
				<NoDataPlaceholder className="comparison-placeholder-no-data"
								   shown={shown}/>
				<DialogRemoteCommands isDarkStyle={props.isDarkStyle}
									  API={props.API}
									  observer={props.observer}
									  onClear={handleClearRemoteCommands}
									  resellerAccounts={resellerAccounts}
									  customerAccounts={customerAccounts}
									  selected={selected}
									  opened={opened} />
				<DialogCustomNameManagement   isDarkStyle={props.isDarkStyle}
											  API={props.API}
											  observer={props.observer}
											  onClear={handleClearCustomNameManagement}
											  selected={selected}
											  boxPreferences={boxPreferences} />
				<DialogSendEmailToAccountOwner isDarkStyle={props.isDarkStyle}
											   API={props.API}
											   observer={props.observer}
											   onClear={handleClearSendEmailToAccountOwner}
											   selected={selected}
											   account={selectedAccount}
											   opened={displayed} />
			</div>
		</React.Fragment>
	);
}
