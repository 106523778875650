import React from "react";
import UUID from "../../../Utils/UUID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import './CurrencySelector.css';

const factoryUUID = new UUID();

export default function CurrencySelector(props) {
    const id = factoryUUID.generate();
    const [value, setValue] = React.useState(props.defaultValue ? props.defaultValue : "EUR");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        const anchor = document.querySelector( `div.search` );
        if( anchor ) {
            setAnchorEl( anchor );
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let css = "CurrencySelector-root";
    let cssSelect = "CurrencySelector-Label";
    let cssLabel = "CurrencySelector-Select";
    let cssMenu = "p-0";
    let cssContent = "dropdown-menu-md overflow-hidden p-3 Menu-CashMonitor-Popup";
    let cssPaper = "";

    if (props.isDarkStyle) {
        css += " dark";
        cssSelect += " dark";
        cssLabel += " dark";
        cssContent += " dark";
        cssPaper += " dark";
    }

    if (props.className) {
        css += " " + props.className;
    }

    const renderCurrencySymbol = code => {
        switch ( code ) {
            case 'USD':
                return <FontAwesomeIcon key={code} icon="fa-solid fa-dollar-sign" />;
            case 'YEN':
                return <FontAwesomeIcon key={code} icon="fa-solid fa-yen-sign" />;
            case 'GPD':
                return <FontAwesomeIcon key={code} icon="fa-solid fa-sterling-sign" />;
            case 'ILS':
                return <FontAwesomeIcon key={code} icon="fa-solid fa-shekel-sign" />;
            case 'XPF':
                return 'XPF';
            case 'CHF':
                return 'CHF';
            default:
                return <FontAwesomeIcon key={code} icon="fa-solid fa-euro-sign" />;
        }
    };

    const currency = renderCurrencySymbol( value );
    if( props.currencies.length < 2 ) {
        return null;
    }
    return (
        <div key={id} className={css}>
            <IconButton className="Icon" color="inherit" disableRipple onClick={handleClick}>
                {currency}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                classes={{ list: cssMenu, paper: cssPaper }}
                onClose={handleClose}
            >
                <div className={cssContent}>
                    <div className="grid-menu grid-menu-1col">
                        {
                            props.currencies.map( currencyCode => <Button key={`btn-${currencyCode}`}
                                                                          variant="text"
                                                                          className="Item hydrated compact"
                                                                          disableRipple
                                                                          onClick={evt => {
                                                                              setValue( currencyCode );
                                                                              if( props.onCurrencySelection ) {
                                                                                  props.onCurrencySelection( currencyCode );
                                                                              }
                                                                              handleClose();
                                                                          }} >
                                {renderCurrencySymbol( currencyCode )}
                            </Button> )
                        }
                    </div>
                </div>
            </Menu>
        </div>
    );
}
