import React from 'react';
import Dialog from "@mui/material/Dialog";
import { I18n } from "aws-amplify/utils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import './DialogSendEmailToAccountOwner.css';
import TextField from "@mui/material/TextField";
import { getUserAttributes } from "../../../graphql/queries";
import { sendEmail } from "../../../graphql/mutations";

const DialogSendEmailToAccountOwner = props => {
    const [body, setBody] = React.useState(  null );
    const [subject, setSubject] = React.useState(  null );

    const handleOk = () => {
        props.onClear();
    };

    const handleSubmit = () => {
        console.log( 'Do action here' );
        if( props.API && props.account && hasValidCognitoId( props.account.accountownerid ) ) {
            props.API
                .graphql({ query: getUserAttributes, variables: { cognitoId: props.account.accountownerid }})
                .then( returned => extractEmailForUser( returned ) )
                .then( email => sendEmailTo( email ) )
                .then( () => {
                    console.log( 'Email sent' );
                    handleOk();
                } )
                .catch(error => {
                    console.error("error" , error);
                    handleOk();
                })
        }
    };

    const handleInputChangeBody = () => {
        const field = document.querySelector( `#message-body` );
        if( field ) {
            setBody( field.value );
        }
    }

    const handleInputChangeSubject = () => {
        const field = document.querySelector( `#message-subject` );
        if( field ) {
            setSubject( field.value );
        }
    }

    const isSubmitDisabled = () => {
        return ! body || body.trim() === '' || ! subject || subject.trim() === '';
    }

    const hasValidCognitoId = cognitoId => {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return uuidRegex.test(cognitoId );
    };

    const extractEmailForUser = returned => {
        return new Promise( resolve => {
            const cognitoData = JSON.parse( returned.data.getUserAttributes.replaceAll( "=" , ":" ) ).Users[0];
            resolve( cognitoData.Username );
        } );
    }

    const sendEmailTo = emailAddress => {
        return new Promise( resolve => {
            props.API.graphql({ query: sendEmail, variables: {
                    input: {
                        recipients: emailAddress,
                        subject: subject,
                        body: buildBodyWithPayStationInfo()
                    }
                } } )
                .then((returned) => {
                    console.log("returned" , returned);
                    resolve();
                })
                .catch((error) => {
                    console.log("error" , error);
                    resolve();
                });
        } );
    }

    const buildBodyWithPayStationInfo = () => {
        const domRootElement = document.querySelector(`#installation-${props.account.payStation.box.id}`);
        const offset = new Date().getTimezoneOffset();
        const localizedDate = new Date( Date.now() - offset );
        const formattedDate = localizedDate.toLocaleDateString( props.locale ) + " " + localizedDate.toLocaleTimeString( props.locale );
        const template = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="format-detection" content="telephone=no">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title></title>
    <style type="text/css" emogrify="no">
      #outlook a {
        padding: 0;
      }

      .ExternalClass {
        width: 100%;
      }

      .ExternalClass,
      .ExternalClass p,
      .ExternalClass span,
      .ExternalClass font,
      .ExternalClass td,
      .ExternalClass div {
        line-height: 100%;
      }

      table td {
        border-collapse: collapse;
        mso-line-height-rule: exactly;
      }

      .editable.image {
        font-size: 0 !important;
        line-height: 0 !important;
      }

      .nl2go_preheader {
        display: none !important;
        mso-hide: all !important;
        mso-line-height-rule: exactly;
        visibility: hidden !important;
        line-height: 0px !important;
        font-size: 0px !important;
      }

      body {
        width: 100% !important;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        margin: 0;
        padding: 0;
      }

      img {
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }

      a img {
        border: none;
      }

      table {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }

      th {
        font-weight: normal;
        text-align: left;
      }

      *[class="gmail-fix"] {
        display: none !important;
      }
    </style>
    <style type="text/css" emogrify="no">
      @media (max-width: 600px) {
        .gmx-killpill {
          content: ' \\03D1';
        }
      }
    </style>
    <style type="text/css" emogrify="no">
      @media (max-width: 600px) {
        .gmx-killpill {
          content: ' \\03D1';
        }

        .r0-o {
          border-style: solid !important;
          margin: 0 auto 0 auto !important;
          width: 100% !important
        }

        .r1-i {
          background-color: #eff2f7 !important
        }

        .r2-c {
          box-sizing: border-box !important;
          text-align: center !important;
          valign: top !important;
          width: 320px !important
        }

        .r3-o {
          border-style: solid !important;
          margin: 0 auto 0 auto !important;
          width: 320px !important
        }

        .r4-i {
          padding-bottom: 20px !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
          padding-top: 20px !important
        }

        .r5-c {
          box-sizing: border-box !important;
          display: block !important;
          valign: top !important;
          width: 100% !important
        }

        .r6-o {
          border-style: solid !important;
          width: 100% !important
        }

        .r7-i {
          padding-left: 0px !important;
          padding-right: 0px !important
        }

        .r8-c {
          box-sizing: border-box !important;
          padding-top: 15px !important;
          text-align: left !important;
          valign: top !important;
          width: 100% !important
        }

        .r9-i {
          background-color: #ffffff !important
        }

        .r10-c {
          box-sizing: border-box !important;
          text-align: center !important;
          valign: top !important;
          width: 100% !important
        }

        .r11-i {
          padding-bottom: 20px !important;
          padding-left: 10px !important;
          padding-right: 10px !important;
          padding-top: 20px !important
        }

        .r12-o {
          background-size: auto !important;
          border-bottom-color: #ffffff !important;
          border-bottom-width: 3px !important;
          border-left-color: #ffffff !important;
          border-left-width: 3px !important;
          border-right-color: #ffffff !important;
          border-right-width: 3px !important;
          border-style: solid !important;
          border-top-color: #ffffff !important;
          border-top-width: 3px !important;
          margin: 0 auto 0 auto !important;
          width: 100% !important
        }

        .r13-i {
          padding-bottom: 15px !important;
          padding-top: 15px !important
        }

        .r14-o {
          border-style: solid !important;
          margin: 0 auto 0 0 !important;
          width: 100% !important
        }

        .r15-i {
          padding-bottom: 15px !important;
          padding-top: 15px !important;
          text-align: left !important
        }

        .r16-c {
          box-sizing: border-box !important;
          text-align: left !important;
          valign: top !important;
          width: 100% !important
        }

        .r17-i {
          color: #3b3f44 !important;
          padding-bottom: 0px !important;
          padding-top: 15px !important;
          text-align: center !important
        }

        body {
          -webkit-text-size-adjust: none
        }

        .nl2go-responsive-hide {
          display: none
        }

        .nl2go-body-table {
          min-width: unset !important
        }

        .mobshow {
          height: auto !important;
          overflow: visible !important;
          max-height: unset !important;
          visibility: visible !important
        }

        .resp-table {
          display: inline-table !important
        }

        .magic-resp {
          display: table-cell !important
        }
      }
    </style>
    <!--[if !mso]>
\t\t\t\t\t\t<!-->
    <style type="text/css" emogrify="no">
      @import url("https://fonts.googleapis.com/css2?family=Roboto");
      @import url("https://fonts.googleapis.com/css2?family=S");
    </style>
    <!--
\t\t\t\t\t\t<![endif]-->
    <style type="text/css">
      p,
      h1,
      h2,
      h3,
      h4,
      ol,
      ul,
      li {
        margin: 0;
      }

      .nl2go-default-textstyle {
        color: #3b3f44;
        font-family: verdana, geneva, sans-serif;
        font-size: 18px;
        line-height: 1.5;
        word-break: break-word
      }

      .default-button {
        color: #000000;
        font-family: verdana, geneva, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        line-height: 1.15;
        text-decoration: none;
        word-break: break-word
      }

      a,
      a:link {
        color: #75b738;
        text-decoration: underline
      }

      .default-heading1 {
        color: #1F2D3D;
        font-family: verdana, geneva, sans-serif, Arial;
        font-size: 36px;
        word-break: break-word
      }

      .default-heading2 {
        color: #1F2D3D;
        font-family: verdana, geneva, sans-serif, Arial;
        font-size: 32px;
        word-break: break-word
      }

      .default-heading3 {
        color: #1F2D3D;
        font-family: verdana, geneva, sans-serif, Arial;
        font-size: 24px;
        word-break: break-word
      }

      .default-heading4 {
        color: #1F2D3D;
        font-family: verdana, geneva, sans-serif, Arial;
        font-size: 18px;
        word-break: break-word
      }

      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }

      .no-show-for-you {
        border: none;
        display: none;
        float: none;
        font-size: 0;
        height: 0;
        line-height: 0;
        max-height: 0;
        mso-hide: all;
        overflow: hidden;
        table-layout: fixed;
        visibility: hidden;
        width: 0;
      }
    </style>
    <!--[if mso]>
\t\t\t\t\t\t<xml>
\t\t\t\t\t\t\t<o:OfficeDocumentSettings>
\t\t\t\t\t\t\t\t<o:AllowPNG/>
\t\t\t\t\t\t\t\t<o:PixelsPerInch>96</o:PixelsPerInch>
\t\t\t\t\t\t\t</o:OfficeDocumentSettings>
\t\t\t\t\t\t</xml>
\t\t\t\t\t\t<![endif]-->
  </head>
  <body bgcolor="#ffffff" text="#3b3f44" link="#75b738" yahoo="fix" style="background-color: #ffffff;">
    <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="background-color: #ffffff; width: 100%;">
      <tr>
        <td>
          <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r0-o" style="table-layout: fixed; width: 100%;">
            <tr>
              <td valign="top" class="r1-i" style="background-color: #eff2f7;">
                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" align="center" class="r3-o" style="table-layout: fixed; width: 600px;">
                  <tr>
                    <td class="r4-i" style="padding-bottom: 20px; padding-top: 20px;">
                      <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                        <tr>
                          <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r6-o" style="table-layout: fixed; width: 100%;">
                              <tr>
                                <td valign="top" class="r7-i" style="padding-left: 15px; padding-right: 15px;">
                                  <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                    <tr>
                                      <td class="r8-c nl2go-default-textstyle" align="left" style="color: #3b3f44; font-family: verdana,geneva,sans-serif; font-size: 18px; line-height: 1.5; word-break: break-word; padding-top: 15px; text-align: left; valign: top;">
                                        <div>
                                          <h3 class="default-heading3" style="margin: 0; color: #1f2d3d; font-family: verdana,geneva,sans-serif,Arial; font-size: 24px; word-break: break-word; text-align: center;">${I18n.get('New message')}</h3>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" align="center" class="r3-o" style="table-layout: fixed; width: 600px;">
            <tr>
              <td valign="top" class="r9-i" style="background-color: #ffffff;">
                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r0-o" style="table-layout: fixed; width: 100%;">
                  <tr>
                    <td class="r11-i" style="padding-bottom: 20px; padding-top: 20px;">
                      <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                        <tr>
                          <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="450" align="center" class="r12-o" style="border-bottom-color: #ffffff; border-bottom-width: 3px; border-collapse: separate; border-left-color: #ffffff; border-left-width: 3px; border-radius: 24px; border-right-color: #ffffff; border-right-width: 3px; border-style: solid; border-top-color: #ffffff; border-top-width: 3px; table-layout: fixed; width: 450px;">
                              <tr>
                                <td class="r13-i" style="border-radius: 21px; font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                  <img src="https://img.mailinblue.com/2577642/images/content_library/original/677bfdf9182e5713688e3b10.jpg" width="444" border="0" style="display: block; width: 100%; border-radius: 21px;">
                                </td>
                              </tr>
                            </table>
                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="left" class="r14-o" style="table-layout: fixed; width: 100%;">
                              <tr>
                                <td align="left" valign="top" class="r15-i nl2go-default-textstyle" style="color: #3b3f44; font-family: verdana,geneva,sans-serif; font-size: 18px; line-height: 1.5; word-break: break-word; padding-bottom: 15px; padding-top: 15px; text-align: left;">
                                  <div>
                                    <p style="margin: 0; text-align: center;">
                                      <strong>🚨CashMonitor ${I18n.get('send you a message')} !🚨</strong>
                                    </p>
                                    <p style="margin: 0; text-align: center;">
                                      <br>${I18n.get('Hello')} ${domRootElement.querySelector(`.reseller-name`).textContent.trim()}
                                    </p>
                                    <p style="margin: 0; text-align: center;">CashMonitor ${I18n.get('send you a message for the customer')} : ${domRootElement.querySelector(`.box-name .main`).textContent} ${domRootElement.querySelector(`.box-name .extra`).textContent}</p>
                                    <p style="margin: 0; text-align: center;">${domRootElement.querySelector(`.software-block .name`).textContent} ${domRootElement.querySelector(`.software-block .version`).textContent} ${domRootElement.querySelector(`.software-block .type`).textContent}</p>
                                    <p style="margin: 0; text-align: center;">${domRootElement.querySelector(`.device-block .device-item:nth-child(1)`).textContent} / ${domRootElement.querySelector(`.device-block .device-item:nth-child(2)`).textContent}</p>
                                    <p style="margin: 0; text-align: center;"></p>
                                    <p style="margin: 0; text-align: center;"></p>
                                    <p style="margin: 0; text-align: center;">${body}</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="r0-o" style="table-layout: fixed; width: 100%;">
            <tr>
              <td valign="top" class="r1-i" style="background-color: #eff2f7;">
                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" align="center" class="r3-o" style="table-layout: fixed; width: 600px;">
                  <tr>
                    <td class="r4-i" style="padding-bottom: 20px; padding-top: 20px;">
                      <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                        <tr>
                          <th width="100%" valign="top" class="r5-c" style="font-weight: normal;">
                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r6-o" style="table-layout: fixed; width: 100%;">
                              <tr>
                                <td valign="top" class="r7-i" style="padding-left: 15px; padding-right: 15px;">
                                  <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                    <tr>
                                      <td class="r16-c" align="left">
                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                          <tr>
                                            <td align="center" valign="top" class="r17-i nl2go-default-textstyle" style="font-family: verdana,geneva,sans-serif; word-break: break-word; color: #3b3f44; font-size: 18px; line-height: 1.5; padding-top: 15px; text-align: center;">
                                              <div>
                                                <p style="margin: 0;">
                                                  <strong>${I18n.get('Info')} :</strong>
                                                </p>
                                                <p style="margin: 0;">
                                                  <strong>${domRootElement.querySelector(`.customer-name`).textContent.trim()}</strong>
                                                </p>
                                                <p style="margin: 0;">
                                                  <strong>${I18n.get('At')} ${formattedDate}</strong>
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
        `;
        return template;
    }

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
    if( props.isDarkStyle ) {
        css += " dark";
    }

    if( ! props.opened ) {
        return null;
    }

    const titleKey = ( props.title ) ? props.title : "Message";
    return (
        <Dialog disableEscapeKeyDown
                className={css}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.opened)}>
            <DialogTitle id="remote-command-dialog-title">{I18n.get( titleKey )}</DialogTitle>
            <DialogContent dividers>
                <TextField
                    id="message-subject"
                    className={`message-body`}
                    placeholder={I18n.get("Subject")}
                    onChange={ () => handleInputChangeSubject() }
                />
                <TextField
                    id="message-body"
                    className={`message-body`}
                    placeholder={I18n.get("Type your email")}
                    multiline
                    rows={7}
                    onChange={ () => handleInputChangeBody() }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>
					<span className="btn-wrapper--label">
						{I18n.get("CANCEL")}
					</span>
                </Button>
                <Button disabled={isSubmitDisabled()} onClick={handleSubmit}>
					<span className="btn-wrapper--label">
						{I18n.get("Send")}
					</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogSendEmailToAccountOwner;
