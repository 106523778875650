import React from 'react';
import { I18n } from 'aws-amplify/utils';
import {
	TextField,
	List,
	InputAdornment,
	ListItem
} from "@mui/material";

import './LicenseManager.css';
import {Search} from "@mui/icons-material";
import NoDataPlaceholder from "../NoDataPlaceholder/NoDataPlaceholder";
import Button from "@mui/material/Button";
import UUID from "../../../Utils/UUID";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const factoryUUID = new UUID();
const Mode = {
	USERS: 'USERS',
	ACCOUNTS: 'ACCOUNTS'
};

export default function LicenseMoveHistory( props ) {
	const [filterName, setFilterName] = React.useState( null );
	const [mode, setMode] = React.useState( Mode.ACCOUNTS );

	const onFilterNameChanges = evt => {
		setFilterName( evt.target.value );
	};

	const filterByName = list => {
		if( ! filterName ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( candidate => {
				if( candidate.name.includes( filterName ) ) {
					filtered.push( candidate );
				}
			} );
		}
		return filtered;
	}

	const renderHeader = () => {
		return (
			<div className={`header-admin`}>
				<div className={`action-bar`}>
					<div className={`title`}>{ I18n.get("History") }</div>
					<div className={`count`}>({ ( filtered ) ? filtered.length : 0 })</div>
					<div className={`spacer`}></div>
					<div className={`actions`}>
						<Button className={`${(mode === Mode.USERS) ? 'selected' : ''} selection-button`}
								key={factoryUUID.generate()}
								onClick={ () => {
									setMode( Mode.USERS );
								}}> {I18n.get('Users')} </Button>
						<Button className={`${(mode === Mode.ACCOUNTS ) ? 'selected' : ''} selection-button`}
								key={factoryUUID.generate()}
								onClick={ () => {
									setMode( Mode.ACCOUNTS );
								}}> {I18n.get('Accounts')} </Button>
					</div>
				</div>
				<div className={`filter-bar`}>
					<TextField id="search-installations"
							   className={`search-field-expand`}
							   label={ I18n.get("Filter account by name") }
							   variant="outlined"
							   size="small"
							   InputProps={{
								   startAdornment: (
									   <InputAdornment position="start">
										   <Search />
									   </InputAdornment>
								   ),
							   }}
							   onChange={ onFilterNameChanges }/>
					<div className={`spacer`}></div>
				</div>
			</div>
		);
	};

	const renderListItem = candidate => {
		const state = "collapsed";
		candidate.id = ( candidate.uuid ) ? candidate.uuid : candidate.accountId;
		return (
			<ListItem key={candidate.id}
					  className={`payStation-card `}
					  onClick={() => {
						  const selection = window.getSelection();
						  if (selection.toString().length > 0) {
							  return;
						  }

						  const expandable = document.querySelector( `.item-expandable .expandable.candidate-${candidate.id}` );
						  if( expandable ) {
							  if( expandable.classList.contains( state ) ) {
								  expandable.classList.remove( state );
							  } else {
								  expandable.classList.add( state );
							  }
						  }
					  }}>
				<div className={`item-expandable`}>
					<div className={`main`}>
						<div  className={`general-block`}>
							<div className={`box-name`}>
								<div className={`main`}>{candidate.name}</div>
							</div>
							<div className={`spacer`}></div>
							{renderLicensesCounts( candidate )}
						</div>
					</div>
					<div className={`expandable ${state} candidate-${candidate.id}`}>
						{renderMovementDetails( candidate.logs )}
					</div>
				</div>
			</ListItem>
		);
	};

	const renderLicensesCounts = candidate => {
		let added = 0;
		let removed = 0;
		let gap = 0;
		let lastAction = new Date( 0 ).toISOString();
		let latestValue = 0;
		candidate?.logs?.forEach( log => {
			const details = JSON.parse( log.details );
			console.log( 'details' , details );
			added += ( parseInt( details.movement , 10 ) > 0 ) ? parseInt( details.movement , 10 ) : 0;
			removed += ( parseInt( details.movement , 10 ) < 0 ) ? parseInt( Math.abs( details.movement ) , 10 ) : 0;
			gap += parseInt( details.movement , 10 );
			latestValue = ( log.at > lastAction ) ? parseInt( details.newValue , 10 ): latestValue;
			lastAction = ( log.at > lastAction ) ? log.at : lastAction;
		} );
		return (
			<div className={`license-count-container`}>
				<div className={`count-block`}>
					<FontAwesomeIcon icon="fa-solid fa-user-plus" />
					<div className={`count-label`}> { added } </div>
				</div>
				<div className={`count-block`}>
					<FontAwesomeIcon icon="fa-solid fa-user-minus" />
					<div className={`count-label`}> { removed } </div>
				</div>
				<div className={`count-block`}>
					<FontAwesomeIcon icon="fa-solid fa-award" />
					<div className={`count-label`}> { gap } </div>
				</div>
				<div className={`count-block`}>
					<div className={`count-label`}> { I18n.get("Available licenses") } : </div>
					<div className={`small-space`}></div>
					<div className={`count-label`}> { ( latestValue === -1 ) ? ' ' + I18n.get("Unlimited") : ` ${latestValue}` } </div>
				</div>
				<div className={`datetime-block`}>
					<div className={`datetime-label`}> { lastAction.split('.')[0].replace('T' , ' ') } </div>
				</div>
			</div>
		);
	}

	const renderMovementDetails = logs => {
		const nodataLabel = ( logs?.length < 1 ) ? I18n.get('No available data') : '';
		const lines = [];
		if( logs?.length > 0 ) {
			lines.push(
				<div className={`line header`}>
					<div className={`column label`}>{ ( mode === Mode.USERS ) ? I18n.get('Accounts') : I18n.get('Users') }</div>
					<div className={`column old-value value`}>{ I18n.get('Old value') }</div>
					<div className={`column new-value value`}>{ I18n.get('New Value') }</div>
					<div className={`column gap-value value`}>{ I18n.get('Movement') }</div>
					<div className={`column at`}>{ I18n.get('At') }</div>
				</div>
			);
			logs?.forEach( log => {
				const details = JSON.parse( log.details );
				let label;
				if( mode === Mode.USERS ) {
					label = props.accounts?.filter( item => item.id === details.accountId )[0].name;
				} else {
					label = props.users.filter( item => item.cognitoid === log.userId )[0].userlabel;
				}
				lines.push(
					<div className={`line data`}>
						<div className={`column label`}>{ label }</div>
						<div className={`column old-value value`}>{ ( details.oldValue === -1 ) ? <FontAwesomeIcon icon="fa-solid fa-infinity" /> : details.oldValue }</div>
						<div className={`column new-value value`}>{ ( details.newValue === -1 ) ? <FontAwesomeIcon icon="fa-solid fa-infinity" /> : Math.abs( details.newValue ) }</div>
						<div className={`column gap-value value`}>{ details.movement }</div>
						<div className={`column at`}>{ log.at.split('.')[0].replace('T' , ' ') }</div>
					</div>
				);
			} );
		}
		return(
			<>
				<div className={`spacer top`}></div>
				<div>{nodataLabel}</div>
				<div className={`mvt-table`}>
					{lines.map( line => line )}
				</div>
			</>
		);
	}

	const formatByUsers = () => {
		const formatted = [];
		props.logs?.forEach( log => {
			let userItem = formatted.filter( item => item.uuid === log.userId );
			if( ! userItem || userItem.length < 1 ) {
				userItem = {
					uuid: log.userId,
					name: props.users.filter( item => item.cognitoid === log.userId )[0].userlabel,
					logs: []
				}
				formatted.push( userItem );
			} else {
				userItem = userItem[0];
			}
			userItem.logs.push( log );
		} );
		return formatted;
	};

	const formatByAccounts = () => {
		const formatted = [];
		props.logs?.forEach( log => {
			const details = JSON.parse( log.details );
			if( details.accountId && details.accountId > 0 ) {
				let accountItem = formatted.filter( item => item.accountId === details.accountId );
				if( ! accountItem || accountItem.length < 1 ) {
					const account = props.accounts?.filter( item => item.id === details.accountId )[0];
					accountItem = {
						accountId: details.accountId,
						name: account.name,
						logs: [],
						account: account

					}
					formatted.push( accountItem );
				} else {
					accountItem = accountItem[0];
				}
				accountItem.logs.push( log );
			}
		} );
		return formatted;
	};

	const formatForDisplay = () => {
		switch ( mode ) {
			case Mode.USERS: return formatByUsers();
			case Mode.ACCOUNTS: return formatByAccounts();
			default : return {};
		}
	};

	const filtered = filterByName(  formatForDisplay() );
	const items = [];
	filtered?.forEach( candidate => {
		items.push( renderListItem( candidate ) );
	} );

	const shown = filtered.length < 1;

	return (
		<React.Fragment>
			<div id="installation-manager">
				{renderHeader()}
				<List>
					{items.map( item => item )}
				</List>
				<NoDataPlaceholder className="comparison-placeholder-no-data"
								   shown={shown}/>
			</div>
		</React.Fragment>
	);
}
